import { Fragment } from "react"
import PanneauxInter from "../../Component/PanneauxInter"

function PanneauSimple() {
  
  const avFr= [
    {av: "Les panneaux en acier sont connus pour leur résistance élevée, ce qui les rend adaptés à des applications nécessitant une robustesse structurelle.", title: "Résistance structurelle:"},
    {av: "Les panneaux en acier peuvent être conçus pour être relativement légers tout en conservant leur résistance, ce qui facilite le transport, la manutention et l'installation sur le site.", title: "Légèreté:"},
    {av: "Les panneaux en acier peuvent être préfabriqués en usine, ce qui permet une installation rapide sur le site de construction, réduisant ainsi les coûts de main-d'œuvre et accélérant le calendrier de construction.", title: "Rapidité de construction:"},
    {av: "L'acier est l'un des matériaux les plus recyclables. Les panneaux en acier peuvent être récupérés et recyclés à la fin de leur vie utile, contribuant ainsi à des pratiques durables.", title: "Recyclabilité:"},
    {av: "En raison de la facilité de fabrication et de la disponibilité des matériaux, les panneaux en acier peuvent offrir des coûts compétitifs par rapport à d'autres options de construction.", title: "Coûts compétitifs:"}
  ]
  const appFr= [
    {app: "Ce sont les barres d'acier principales qui forment la structure du treillis. Elles sont disposées parallèlement les unes aux autres, espacées à une distance spécifique.", title: "Barres Principales (Longitudinales):"},
    {app: "Ces barres d'acier sont soudées perpendiculairement aux barres principales pour former des intersections. Elles créent ainsi une grille régulière.", title: "Barres Transversales (Transversales):"},
    {app: "Les mailles sont les espaces entre les barres principales et transversales. La taille des mailles peut varier en fonction des spécifications du treillis.", title: "Mailles:"},
    {app: "Les points d'intersection entre les barres principales et transversales sont soudés pour assurer la stabilité structurelle du panneau.", title: "Soudure:"},
    {app: "Certains panneaux de treillis simples peuvent avoir des bords renforcés pour améliorer la résistance et prévenir les dommages pendant la manipulation et l'installation.", title: "Bords Renforcés (Facultatif):"},
    {app: "Les dimensions du panneau, telles que la longueur, la largeur et l'épaisseur des barres, déterminent la taille globale et la résistance du treillis.", title: "Dimensions"},
    {app: "Certains panneaux peuvent être revêtus d'un matériau supplémentaire, comme de l'acier galvanisé, pour améliorer la résistance à la corrosion.", title: "Revêtement (le cas échéant):"}
  ]

  const avGb= [
    {av: "Steel panels are known for their high strength, making them suitable for applications requiring structural robustness.", title: "Structural strength:"},
     {av: "Steel panels can be designed to be relatively lightweight while maintaining strength, making it easier to transport, handle and install on site.", title: "Lightness:"},
     {av: "Steel panels can be prefabricated in the factory, allowing rapid installation at the construction site, thereby reducing labor costs and accelerating the construction schedule.", title: "Speed of construction:"},
     {av: "Steel is one of the most recyclable materials. Steel panels can be recovered and recycled at the end of their useful life, contributing to sustainable practices.", title: "Recyclability:"} ,
     {av: "Due to ease of manufacturing and availability of materials, steel panels can offer competitive costs compared to other construction options.", title: "Competitive costs:"}
  ]
  const appGb= [
    {app: "These are the main steel bars that form the truss structure. They are arranged parallel to each other, spaced at a specific distance.", title: "Main Bars (Longitudinal):"},
     {app: "These steel bars are welded perpendicular to the main bars to form intersections. They thus create a regular grid.", title: "Transverse Bars (Transversales):"},
     {app: "Meshes are the spaces between the main and crossbars. Mesh sizes may vary depending on truss specifications.", title: "Meshes:"},
     {app: "The intersection points between the main and cross bars are welded to ensure the structural stability of the panel.", title: "Welding:"},
     {app: "Some simple mesh panels may have reinforced edges to improve strength and prevent damage during handling and installation.", title: "Reinforced Edges (Optional):"},
     {app: "The dimensions of the panel, such as the length, width and thickness of the bars, determine the overall size and strength of the mesh.", title: "Dimensions"},
     {app: "Some panels may be coated with an additional material, such as galvanized steel, to improve corrosion resistance.", title: "Coating (if applicable):"}
  ]

  const avDz= [
    {av: "تشتهر الألواح الفولاذية بقوتها العالية، مما يجعلها مناسبة للتطبيقات التي تتطلب متانة هيكلية.", title: "القوة الهيكلية:"},
     {av: "يمكن تصميم الألواح الفولاذية لتكون خفيفة الوزن نسبيًا مع الحفاظ على قوتها، مما يسهل نقلها والتعامل معها وتركيبها في الموقع.", title: "Lightness:"},
     {av: "يمكن تصنيع الألواح الفولاذية مسبقًا في المصنع، مما يسمح بالتركيب السريع في موقع البناء، وبالتالي تقليل تكاليف العمالة وتسريع الجدول الزمني للبناء.", title: "سرعة البناء:"},
     {av: "الصلب هو أحد أكثر المواد القابلة لإعادة التدوير. يمكن استعادة الألواح الفولاذية وإعادة تدويرها في نهاية عمرها الإنتاجي، مما يساهم في الممارسات المستدامة.", title: "Recyclability:"} ,
     {av: "بسبب سهولة التصنيع وتوافر المواد، يمكن أن توفر الألواح الفولاذية تكاليف تنافسية مقارنة بخيارات البناء الأخرى.", title: "التكاليف التنافسية:"}
  ]
  const appDz= [
    {app: "هذه هي القضبان الفولاذية الرئيسية التي تشكل هيكل الجمالون. وهي مرتبة بالتوازي مع بعضها البعض، ومتباعدة على مسافة محددة.", title: "القضبان الرئيسية (الطولية):"},
     {app: "يتم لحام هذه القضبان الفولاذية بشكل عمودي على القضبان الرئيسية لتكوين تقاطعات. وبالتالي يتم إنشاء شبكة منتظمة.", title: "القضبان المستعرضة (المستعرضة):"},
     {app: "الشبكات هي المسافات بين الأعمدة الرئيسية والعارضة. قد تختلف أحجام الشبكات وفقًا لمواصفات الجمالون.", title: "الشبكات:"},
     {app: "يتم لحام نقاط التقاطع بين القضبان الرئيسية والقضبان المتقاطعة لضمان الاستقرار الهيكلي للوحة.", title: "لحام:"},
     {app: "قد تحتوي بعض اللوحات الشبكية البسيطة على حواف معززة لتحسين القوة ومنع الضرر أثناء المعالجة والتركيب.", title: "الحواف المقواة (اختياري):"},
     {app: "تحدد أبعاد اللوحة، مثل طول الأشرطة وعرضها وسمكها، الحجم الإجمالي للشبكة وقوتها.", title: "الأبعاد"},
     {app: "قد يتم طلاء بعض الألواح بمادة إضافية، مثل الفولاذ المجلفن، لتحسين مقاومة التآكل.", title: "الطلاء (إن أمكن):"}
  ]
  return (
    <div>
        {/*<PanneauInter  title= {"PANNEAU SIMPLE"} GoTo= {"panneau_2d"} GoDown= {"panneau_haute_securite"} image= {'simple.jpg'} /> */}
        <PanneauxInter 
          id= "simple"
          GoTo= {"panneau_2d"} GoDown= {"panneau_haute_securite"}
          pdf= {require('../../pdf/Panneaux/simple.pdf')}
          appFr= {appFr} avFr= {avFr}
          appGb= {appGb} avGb= {avGb}
          appDz= {appDz} avDz= {avDz}
          image= {"simple.jpg"}
          figureUno= {"simpleUno.jpg"}
          figureDos= {"simpleDos.jpg"}
        />

    </div>
  )
}

export default PanneauSimple