import { Fragment, useEffect } from "react"
import PageEntete from "../../Component/PageEntete"
import '../../Styles/Panneau.css'
import '../../Styles/Page.css'
import PiquetInter from "../../Component/PiquetInter"

function PiquetCarre() {

    const appFr= [
        {app: "Ces piquets sont fréquemment utilisés pour ériger des clôtures dans des environnements résidentiels, commerciaux ou agricoles.", title: "Construction de clôtures:"},
        {app: "Ils peuvent être employés pour délimiter des zones, soutenir des plantes grimpantes ou marquer des chemins dans des projets d'aménagement paysager.", title: "Aménagement paysager:"},
        {app: "Les piquets carrés sont adaptés pour fixer des panneaux de signalisation temporaires sur des chantiers de construction, des événements spéciaux ou des zones de travaux routiers.", title: "Signalisation temporaire:"},
        {app: "Ils sont utiles pour marquer des zones dans les champs, pour créer des enclos temporaires, ou pour soutenir des filets de protection.", title: "Agriculture:"}
    ]
    const avFr= [
        {av: "Le processus de galvanisation confère une protection efficace contre la corrosion. Cela permet aux piquets de maintenir leur intégrité structurelle même dans des environnements extérieurs difficiles et exposés aux intempéries.", title: "Résistance à la corrosion:"},
        {av: "L'acier galvanisé est connu pour sa durabilité. Les piquets en acier galvanisé carré sont capables de résister à des charges importantes et sont moins susceptibles de se plier ou de se casser par rapport à des matériaux moins robustes.", title: "Durabilité:"},
        {av: "La forme carrée des piquets offre une stabilité supplémentaire, les rendant polyvalents pour une variété d'applications, de la construction de clôtures à l'aménagement paysager en passant par des projets de bricolage.", title: "Polyvalence:"},
        {av: "Ces piquets sont généralement faciles à installer, que ce soit en les enfonçant dans le sol ou en les fixant à d'autres structures. La forme carrée peut également faciliter leur positionnement et leur alignement.", title: "Facilité d'installation:"}
    ]

    const appGb= [
        {app: "These stakes are frequently used to erect fences in residential, commercial or agricultural environments.", title: "Fencing Construction:"},
        {app: "They can be used to demarcate areas, support climbing plants or mark paths in landscaping projects.", title: "Landscaping:"},
        {app: "Square stakes are suitable for attaching temporary signage at construction sites, special events or road work zones.", title: "Temporary signage:"},
        {app: "They are useful for marking areas in fields, for creating temporary enclosures, or for supporting protective netting.", title: "Agriculture:"}
    ]
    const avGb= [
        {av: "The galvanizing process provides effective protection against corrosion. This allows the stakes to maintain their structural integrity even in harsh outdoor environments exposed to the elements.", title: "Corrosion resistance:"},
        {av: "Galvanized steel is known for its durability. Square galvanized steel stakes are able to withstand heavy loads and are less likely to bend or break compared to less sturdy materials.", title : "Sustainability:"},
        {av: "The square shape of the stakes provides additional stability, making them versatile for a variety of applications, from building fences to landscaping to DIY projects.", title: "Versatility:" },
        {av: "These stakes are generally easy to install, either by driving them into the ground or attaching them to other structures. The square shape can also make them easier to position and align.", title: "Ease of installation 'facility:"}
    ]

    const appDz= [
        {app: "يتم استخدام هذه الأوتاد بشكل متكرر لتركيب الأسوار في البيئات السكنية أو التجارية أو الزراعية.", title: "إنشاء السياج:"},
        {app: "يمكن استخدامها لترسيم المناطق أو دعم النباتات المتسلقة أو تحديد المسارات في مشاريع تنسيق الحدائق.", title: "تنسيق الحدائق:"},
        {app: "الأوتاد المربعة مناسبة لإرفاق اللافتات المؤقتة في مواقع البناء أو المناسبات الخاصة أو مناطق العمل على الطرق.", title: "اللافتات المؤقتة:"},
        {app: "إنها مفيدة لوضع علامات على المناطق في الحقول، أو لإنشاء سياجات مؤقتة، أو لدعم الشباك الواقية.", title: "الزراعة:"}
    ]
    const avDz= [
        {av: "توفر عملية الجلفنة حماية فعالة ضد التآكل. وهذا يسمح للأوتاد بالحفاظ على سلامتها الهيكلية حتى في البيئات الخارجية القاسية المعرضة للعناصر.", title: "مقاومة التآكل:"},
        {av: "يشتهر الفولاذ المجلفن بمتانته. إن الأوتاد الفولاذية المجلفنة المربعة قادرة على تحمل الأحمال الثقيلة وأقل عرضة للانحناء أو الكسر مقارنة بالمواد الأقل متانة.", title : "الاستدامة:"},
        {av: "يوفر الشكل المربع للأوتاد ثباتًا إضافيًا، مما يجعلها متعددة الاستخدامات لمجموعة متنوعة من التطبيقات، بدءًا من بناء الأسوار وحتى تنسيق الحدائق وحتى مشاريع الأعمال اليدوية.", title: "التنوع:" },
        {av: "هذه الأوتاد سهلة التثبيت بشكل عام، إما عن طريق دفعها إلى الأرض أو ربطها بهياكل أخرى. كما يمكن للشكل المربع أن يسهل وضعها ومحاذاتها.", title: "منشأة سهولة التثبيت: "}
    ]

  return (
    <div>
        <PiquetInter 
            id= "carre"
            GoTo= {"piquet_rectangulaire"} GoDown= {"piquet_sonatres"}
            pdf= {require('../../pdf/Piquets/80_carre.pdf')}
            appFr= {appFr} avFr= {avFr}
            appGb= {appGb} avGb= {avGb}
            appDz= {appDz} avDz= {avDz}
            mainImage= {"carre.jpg"} platine= {"carre_platine.jpg"} fouille= {"carre_fouille.jpg"}
            bouchon= {"carre_bouchon.jpg"} dimension= {"carre_dimension.jpg"}
        />
    </div>
  )
}

export default PiquetCarre