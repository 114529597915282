import '../../Styles/Page.css'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import PageEntete from '../../Component/PageEntete'
import poutrelle from '../../images/treillis/poutrelle.jpg'
import { useRef, useEffect, Fragment } from 'react'
import TreillisInter from '../../Component/TreillisInter'
import { useTranslation } from 'react-i18next'

function Poutrelle( {nb} ) {
  const [t, i18n]= useTranslation("global")
  const avFr= [
    {av: "L'acier est un matériau très résistant, ce qui en fait un choix idéal pour les poutrelles. Les poutrelles en acier peuvent supporter des charges importantes tout en conservant leur intégrité structurelle.", title: "Résistance élevée:"},
    {av: "L'acier est durable et résistant à la corrosion, ce qui signifie que les poutrelles en acier ont une longue durée de vie. Elles nécessitent moins d'entretien par rapport à d'autres matériaux, comme le bois.", title: "Durabilité:"},
    {av: "Les poutrelles en acier sont fabriquées selon des normes strictes, assurant une uniformité et une prédictibilité dans leurs propriétés. Cela facilite la conception et la construction, car les ingénieurs peuvent compter sur des performances cohérentes.", title: "Uniformité et prédictibilité:"},
    {av: "Contrairement à d'autres matériaux de construction, l'acier conserve sa résistance structurelle à des températures élevées, ce qui en fait un choix sûr dans le cas d'incendies.", title: "Résistance au feu:"},
  ]
  const appFr= [
    {app: "Les treillis soudés en acier avec des poutrelles sont fréquemment utilisés dans la construction de bâtiments commerciaux et résidentiels. Ils peuvent être employés pour former les planchers, les toits et d'autres éléments structurels.", title: "Construction de Bâtiments:"},
    {app: "Ces treillis sont largement utilisés dans la construction de ponts en raison de leur capacité à supporter des charges importantes sur de grandes portées. Ils offrent une solution structurelle solide pour les composants de support des ponts.", title: "Ponts"},
    {app: "Les treillis soudés en acier sont utilisés dans la construction d'infrastructures routières pour créer des structures de soutènement, des passerelles et d'autres éléments nécessaires sur les routes et autoroutes.", title: "Infrastructures Routières"},
    {app: "Ces treillis peuvent être utilisés pour la construction d'échafaudages temporaires sur les chantiers de construction, ainsi que pour des installations temporaires lors d'événements spéciaux.", title: "Structures Temporaires"},
    {app: "Ils peuvent être utilisés dans la construction de maisons à plusieurs étages pour former la structure des planchers et des toits.", title: "Constructions Résidentielles"},
  ]

  const avGb= [
    {av: "Steel is a very strong material, making it an ideal choice for joists. Steel joists can support significant loads while maintaining their structural integrity.", title: "High strength:"} ,
     {av: "Steel is durable and corrosion resistant, which means that steel joists have a long lifespan. They require less maintenance compared to other materials, such as wood.", title: "Sustainability:"},
     {av: "Steel joists are manufactured to strict standards, ensuring uniformity and predictability in their properties. This makes design and construction easier because engineers can rely on consistent performance.", title: "Uniformity and predictability:"},
     {av: "Unlike other building materials, steel maintains its structural strength at high temperatures, making it a safe choice in the event of fires.", title: "Fire resistance:"} ,
  ]
  const appGb= [
    {app: "Welded steel mesh with joists are frequently used in the construction of commercial and residential buildings. They can be used to form floors, roofs and other structural elements.", title: "Building Construction :"},
     {app: "These trusses are widely used in bridge construction due to their ability to support significant loads over large spans. They provide a strong structural solution for bridge support components.", title: "Bridges" },
     {app: "Welded steel mesh is used in the construction of road infrastructure to create retaining structures, walkways and other necessary elements on roads and highways.", title: "Road Infrastructure"},
     {app: "These trellises can be used for the construction of temporary scaffolding on construction sites, as well as for temporary installations during special events.", title: "Temporary Structures"},
     {app: "They can be used in the construction of multi-storey houses to form the structure of floors and roofs.", title: "Residential Constructions"},
  ]

  const avDz= [
    {av: "الفولاذ مادة قوية جدًا، مما يجعله خيارًا مثاليًا للروافد. يمكن للروافد الفولاذية دعم الأحمال الكبيرة مع الحفاظ على سلامتها الهيكلية.", title: "قوة عالية:"} ,
     {av: "الفولاذ متين ومقاوم للتآكل، مما يعني أن الروافد الفولاذية تتمتع بعمر افتراضي طويل. وهي تتطلب صيانة أقل مقارنة بالمواد الأخرى، مثل الخشب.", title: "الاستدامة:"},
     {av: "يتم تصنيع الروافد الفولاذية وفقًا لمعايير صارمة، مما يضمن الاتساق والقدرة على التنبؤ في خصائصها. وهذا يجعل التصميم والبناء أسهل لأن المهندسين يمكنهم الاعتماد على الأداء المتسق.", title: "التوحيد والقدرة على التنبؤ:"},
     {av: "بخلاف مواد البناء الأخرى، يحتفظ الفولاذ بقوته الهيكلية عند درجات الحرارة المرتفعة، مما يجعله خيارًا آمنًا في حالة نشوب الحرائق.", title: "مقاومة الحريق:"} ,
  ]
  const appDz= [
    {app: "يتم استخدام الشبكات الفولاذية الملحومة ذات الروافد بشكل متكرر في تشييد المباني التجارية والسكنية. ويمكن استخدامها لتشكيل الأرضيات والأسقف والعناصر الهيكلية الأخرى.", title: "تشييد المباني :"},
     {app: "تستخدم هذه الجمالونات على نطاق واسع في بناء الجسور نظرًا لقدرتها على دعم الأحمال الكبيرة على مسافات كبيرة. فهي توفر حلاً هيكليًا قويًا لمكونات دعم الجسور.", title: "الجسور" },
     {app: "تُستخدم شبكة الفولاذ الملحومة في إنشاء البنية التحتية للطرق لإنشاء الهياكل الاستنادية والممرات والعناصر الضرورية الأخرى على الطرق والطرق السريعة.", title: "البنية التحتية للطرق"},
     {app: "يمكن استخدام هذه التعريشات لإنشاء سقالات مؤقتة في مواقع البناء، وكذلك للتركيبات المؤقتة أثناء المناسبات الخاصة.", title: "الهياكل المؤقتة"},
     {app: "يمكن استخدامها في بناء المنازل متعددة الطوابق لتشكيل هيكل الأرضيات والأسقف.", title: "الإنشاءات السكنية"},
  ]

  return (
    <>
      <TreillisInter 
        id= "poutrelle"
        GoTo= {"rouleaux"} GoDown= {"cep"}
        pdf= {require('../../pdf/Treillis/poutrelle.pdf')}
        appFr= {appFr} avFr= {avFr}
        appGb= {appGb} avGb= {avGb}
        appDz= {appDz} avDz= {avDz}
        image= {"poutrelle.jpg"}
        figureUno= {"poutrelleUno.jpg"}
        figureDos= {"poutrelleDos.jpg"}
      />
    </>)}
export default Poutrelle