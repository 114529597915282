import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import PageEntete from "./PageEntete"
import '../Styles/Treillis.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Tooltip from '@mui/material/Tooltip'
import 'react-slideshow-image/dist/styles.css'
import { Slide, Fade, Zoom } from 'react-slideshow-image'
import CopyrightIcon from '@mui/icons-material/Copyright'
import vid from '../images/pexels-tima-miroshnichenko-5846591 (1080p)_2.mp4'
import { useMediaQuery } from "@mui/material"
import DownloadIcon from '@mui/icons-material/Download'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import Footer from "./Footer"
import vidMain from '../images/vid_treillis.mp4'
import ReactPlayer from 'react-player'
import { useTranslation } from "react-i18next"

function TreillisInter( {id, GoTo, GoDown, GoToTr, GoDowntr, pdf, image, figureUno, figureDos, dscrp, avFr, avGb, avDz, appFr, appGb, appDz, cep} ) {
    const mobile= useMediaQuery('(max-width: 800px)')
    useEffect(() => {
        window.scrollTo( {top: "0", behavior: "smooth"} )
    }, [])

    const [t, i18n]= useTranslation("global")

    const [fullScreen, setFullScreen]= useState(false)
    const [show, setShow] = useState(true)
    const [lastScrollY, setLastScrollY] = useState(0)
    const [title, setTitle]= useState("")
    const [defs, setDefs]= useState([])
    const [apps, setApps]= useState([])
    const [avs, setAvs]= useState([])
    const [isPlaying, setIsPlaying] = useState(false)

    const controlNavbar = () => {
        if (window.scrollY > lastScrollY) {
        setShow(false); 
        } else {
        setShow(true);  
        }
        setLastScrollY(window.scrollY); 
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);
        return () => {
        window.removeEventListener('scroll', controlNavbar);
        };
    }, [lastScrollY])

    useEffect(()=> {
        AOS.init({duration: 1000})
    }, [])

    useEffect(()=> {
        if(t("lang")=== "fr") {
          setApps(appFr)
          setAvs(avFr)
        }else if(t("lang")=== "gb") {
          setApps(appGb)
          setAvs(avGb)
        }else {
          setApps(appDz)
          setAvs(avDz)
        }
    }, [t("lang")])

    const avsList= avs.map((e)=> {
        return (
            <>
                <h3>{e.title}</h3>
                <p>{e.av}</p>
            </>
        )
    })
    const appsList= apps.map((e)=> {
        return (
            <>
                <h3>{e.title}</h3>
                <p>{e.app}</p>
            </>
        )
    })
    useEffect(()=> {
            Object.entries(t("prods.treillis", {returnObjects: true})).
            map((prods)=> {
                if(prods[0]=== id) {
                    setDefs(t(`prods.treillis.${id}.def`, {returnObjects: true}))
                    setTitle(t(`prods.treillis.${id}.title`))
                }
            })
    }, [t("lang")])
    
    const displayDefs= defs.map(e=> {
        return (
            <>
                {
                    (e.title)&& <p style= {{fontWeight: "bolder", color: "#ff4136"}}>{e.title}:</p>
                }
                 <p>{e.text}</p>
            </>
        )
    })

  return (
    <>
    <div className= 'Treillis' >
        <video className= "vid_cover" src= {vid} autoPlay muted loop />
        <PageEntete type= {t("footer.hlinks.treillis")} title= {title} GoTo= {GoTo} GoDown= {GoDown} pdf= {pdf} show= {show} />
        <h1 id= "Treillis_title" style= {{alignSelf: (t("lang")=== "dz")&& "flex-end" }}>{t("interface.presentation")}</h1>
        <div className="Treillis_first" style= {{direction: (t("lang")=== "dz")&& "rtl" }}>
            <div className= {(!fullScreen) ? "Treillis_first_figures" : "Treillis_first_figures_full"} data-aos="zoom-in">
                <Slide easing="linear" pauseOnHover= {true} autoplay= {true} transitionDuration= {1000} duration= {1000} >
                    <div className="slide">
                        <figure>
                            <Tooltip title= {!fullScreen ? "Plein d'écran" : "Quitter le plein d'écran"} >
                                <img  onClick= {()=> {setFullScreen(!fullScreen)}} src= {require(`../images/treillis/${image}`)} alt="" />
                            </Tooltip>
                            <figcaption>{title}</figcaption>
                        </figure>
                    </div>
                    <div className="slide">
                        <figure>
                            <Tooltip title= {!fullScreen ? "Plein d'écran" : "Quitter le plein d'écran"}>
                                <img  onClick= {()=> {setFullScreen(!fullScreen)}} src= {require(`../images/treillis/${figureUno}`)} alt="" />
                            </Tooltip>
                            <figcaption>{title}</figcaption>
                        </figure>
                    </div>
                    <div className="slide">
                        <figure>
                            <Tooltip title= {!fullScreen ? "Plein d'écran" : "Quitter le plein d'écran"} >
                                <img onClick= {()=> {setFullScreen(!fullScreen)}} src= {require(`../images/treillis/${figureDos}`)} alt="" />
                            </Tooltip>
                            <figcaption>{title}</figcaption>
                        </figure>
                    </div>
                </Slide>
                {/*
                <Tooltip title= {!mainFullScreen? `${title} en plein d'écran`: "Fermer le plein d'écran"} >
                    <img id= {mainFullScreen && "fullScreenImage"} onClick= {()=> {setMainFullScreen(!mainFullScreen)}} src= {require(`../images/treillis/${image}`)} alt="" />
                </Tooltip>
                <div className="Treillis_first_figures_smalls">
                    <img src= {} alt="" />
                    
                    <img id= {unoFullScreen && "fullScreenImage"} onClick= {()=> {setUnoFullScreen(!unoFullScreen)}} src= {require(`../images/treillis/${figure}`)} alt="" />
                    <img id= {dosFullScreen && "fullScreenImage"} onClick= {()=> {setDosFullScreen(!dosFullScreen)}} src= {require(`../images/treillis/${figure}`)} alt="" />
                    <img id= {tresFullScreen && "fullScreenImage"} onClick= {()=> {setTresFullScreen(!tresFullScreen)}} src= {require(`../images/treillis/${figure}`)} alt="" />
                    
                </div>
                */}
            </div>
            <div className="Treillis_first_dscrp" data-aos="fade-up" data-aos-anchor= {mobile && ".Treillis_first_figures"} data-aos-anchor-placement= {mobile && "center-top"} >
                { defs &&displayDefs }
                <h5 className="Treillis_first_dscrp_vid " style= {{alignSelf: "center"}}>
                    <a href="#vid_main">{t("interface.vid")}</a>
                    <a href="#vid_main"><PlayCircleIcon id= "icon" /></a>
                </h5>
            </div>
        </div>
        {!fullScreen
        &&<><div className="Treillis_second" style= {{direction: (t("lang")=== "dz")&& "rtl" }} data-aos="zoom-in-up">
            <div className="Treillis_second_app" data-aos= "fade-down" data-aos-anchor=".Treillis_second" data-aos-anchor-placement="top-center">
                <h1 id= "Treillis_title">{t("interface.app")}</h1>
                {
                    appsList
                }
            </div>
            <div className="Treillis_second_avs">
                <img id= "list_trigger" data-aos="fade-up" data-aos-anchor= {mobile ? ".Treillis_second_app" : ".Treillis_second"} data-aos-anchor-placement= {mobile ? "bottom-center" : "top-center"} src= {require(`../images/treillis/${figureUno}`)} alt="" />
                <div className= "Treillis_second_app_util" data-aos= "fade-left" data-aos-anchor="#list_trigger" data-aos-anchor-placement="bottom-center" >
                    <h1 id= "Treillis_title">{t("interface.av")}</h1>
                    {
                        avsList
                    }
                </div>
            </div>
            <div className="Treillis_second_pdf">
                <h5 className="text_icon">
                    <Link 
                        id= ""
                        to= {pdf}
                        download= {`${title}_Fiche_Technique`}
                        target="_blank"
                        rel="noreferrer"
                        >
                        {t("interface.pdf")}
                    </Link>
                </h5>
                <Link
                    to= {pdf}
                    download= {`${title}_Fiche_Technique`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <DownloadIcon id= "icon" />
                </Link>
            </div>
        </div>

        <div id="vid_main" style= {{height: mobile && "40vh"}}>
            <ReactPlayer url= {vidMain} id= "vid_anchor" controls= {true} style= {{objectFit: mobile ? "contain": "cover"}} height= "100%" width= "100%"
            onPlay= {()=> {setIsPlaying(true)}} onPause= {()=> {setIsPlaying(false)}} 
            data-aos="flip-up" data-aos-anchor=".Treillis_second" data-aos-anchor-placement= "bottom-center"
            />
            <PlayCircleIcon
            data-aos= "zoom-in" data-aos-anchor= "#vid_main" data-aos-anchor-placement= "top-top" 
            style= {{
                color: "#ff4136",
                position: "absolute", top: "50%", left: "50%", translate: "-50% -50%",
                height: "30%", width: "30%", zIndex: 3, pointerEvents: "none",
                transition: "all ease-in-out .25s", opacity: isPlaying&& 0, scale: isPlaying&& 0
            }}
            />
        </div>

        <div className="map">
            <iframe data-aos="flip-up" data-aos-anchor="#vid_anchor" data-aos-anchor-placement= "bottom-center" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26081.919943068973!2d4.116905574316397!3d35.200490599999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128bb33f64e0f135%3A0xcf0c1d87f28f9cb9!2sSARL%20SONATRES!5e0!3m2!1sfr!2sdz!4v1699859258957!5m2!1sfr!2sdz" width="600" height="450" style= {{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <br />
        <Footer id= "footer_treillis" page= "treillis" />
        </>}
    </div>
    </>
  )
}

export default TreillisInter