
import '../Styles/Page.css'
import CopyrightIcon from '@mui/icons-material/Copyright'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import {useNavigate} from 'react-router-dom'
import Langue from './Langue'
import { useTranslation } from 'react-i18next'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

function Footer({change, id, home, page, changeLang}) {
    const navigate= useNavigate()
    const navigation= (route)=> {
        if(page=== route) {
            window.scrollTo( {top: "0", behavior: "smooth"} )
        }
    }
    useEffect(()=> {
        AOS.init({duration: 1000})
    }, [])
    const [t, i18n]= useTranslation("global")
    const textAr= {
        direction: (t("lang")=== "dz")&& "rtl"
    }
  return (
    <footer id= {id} style= {textAr}>
        <div className="footer_first">
            <ul className="footer_Llinks" >
                <h5>{t("footer.llinks.title")}:</h5>
                {home?
                    <>
                        <li onClick= {()=> {change("home")}}>{t("footer.llinks.home_page")}</li>
                        <li onClick= {()=> {change("about")}}>{t("footer.llinks.about")}</li>
                        <li onClick= {()=> {change("contact")}}>{t("footer.llinks.contact")}</li>
                    </>:
                    <>
                        <li onClick= {()=> {navigate("/", {state: {section: "home", firstLoad: false}})}}>{t("footer.llinks.home_page")}</li>
                        <li onClick= {()=> {navigate("/", {state: {section: "about", firstLoad: false}})}}>{t("footer.llinks.about")}</li>
                        <li onClick= {()=> {navigate("/", {state: {section: "contact", firstLoad: false}})}}>{t("footer.llinks.contact")}</li>
                    </>
                }
            </ul>
            <ul className="footer_Hlinks">
                <h5>{t("footer.hlinks.title")}:</h5>
                <li onClick= {()=> {navigate("/", {state: {section: "home", firstLoad: false}}); change("home")}}>{t("footer.hlinks.tous")}</li>
                <li onClick= {()=>{navigate("/poutrelle"); navigation("treillis") }}>{t("footer.hlinks.treillis")}</li>
                <li onClick= {()=> {navigate("/panneau_simple"); navigation("panneaux") }}>{t("footer.hlinks.panneaux")}</li>
                <li onClick= {()=> {navigate("/piquet_rectangulaire"); navigation("piquets") }}>{t("footer.hlinks.piquets")}</li>
            </ul>
            <div className="footer_icons" transition= {{ duration: 0.5 }} initial={{scale: 0, opacity: 1}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 0}} >
                <h5>{t("footer.reseaux")}:</h5>
                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/SARLSONATRES" style= {{color: "#316FF6"}}><FacebookIcon id= "footer_icon" /></a>
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/sonatres-bousaada-2b2952266/" style= {{color: "#0A66C2"}}><LinkedInIcon id= "footer_icon" /></a>
                <a target="_blank" rel="noreferrer" href= "https://www.instagram.com/sonatres_bousaada/" ><InstagramIcon className= 'insta_icon' id= "footer_icon" /></a>
            </div>
            <div className="footer_pos">
                <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/spnWERYqUYcNXTuv6"><LocationOnIcon id= "footer_icon" /></a>
                <h5><a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/spnWERYqUYcNXTuv6">{t("footer.pos")}:</a></h5>
            </div>
            <div className="footer_website">
                <h5><a target="_blank" rel="noreferrer" href="https://dz.kompass.com/c/sonatres-sarl/dz259867/" style= {{transition: "all ease-in-out .5s"}}>{t("footer.website")}:</a></h5>
            </div>
            <Langue id= "footer" changeLang= {changeLang} />
        </div>
        <h6>
            <CopyrightIcon style= {{height: "15px", width: "15px", color: "#ff4136"}} />Copyright 2023<span style= {{color: "#ff4136"}}>SARL Sonatres</span><span>Bou-Saada</span>
        </h6>
    </footer>
  )
}

export default Footer