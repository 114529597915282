import { useState, useEffect, useRef } from 'react';
import './App.css'
import './index.css'
import logo from './images/logo_round.jpg'
import poutrelle from './images/treillis/poutrelle.jpg'
import panneau from './images/treillis/panneau.jpg'
import rouleaux from './images/treillis/rouleaux.jpg'
import simple from './images/panneaux/simple.jpg'
import double from './images/panneaux/2d.jpg'
import triple from './images/panneaux/3d.jpg'
import cep from './images/treillis/cep.jpg'
import lourd from './images/piquets/lourd_platine.jpg'
import lourdHome from './images/piquets/lourd_platine.jpg'
import sonatres from './images/piquets/sonatres_platine.jpg'
import carre from './images/piquets/carre_platine.jpg'
import rec from './images/piquets/rec_platine.jpg'
import haute from './images/panneaux/haute.jpg'
import { motion, Variants, AnimatePresence } from "framer-motion"
import FacebookIcon from '@mui/icons-material/Facebook'
import Button from '@mui/material/Button'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import RingLoader from 'react-spinners/RingLoader'
import SyncLoader from 'react-spinners/SyncLoader'
import ClipLoader from 'react-spinners/ClipLoader'
import { Squash as Hamburger, Squash } from 'hamburger-react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MuiTelInput } from 'mui-tel-input'
import emailjs from '@emailjs/browser'
import {BrowserRouter as Router , Routes ,Route, Link, useLocation, Navigate, useNavigate} from 'react-router-dom'
import Poutrelle from './pages/Treillis/Poutrelle'
import Rouleaux from './pages/Treillis/Rouleaux'
import Panneaux from './pages/Treillis/Panneaux'
import CEP from './pages/Treillis/CEP'
import Panneau2D from './pages/Panneaux/Panneau2D'
import Panneau3D from './pages/Panneaux/Panneau3D'
import PanneauSimple from './pages/Panneaux/PanneauSimple'
import PanneauHaute from './pages/Panneaux/PanneauHaute'
import PiquetRec from './pages/Piquets/PiquetRec'
import PiquetLourd from './pages/Piquets/PiquetLourd'
import PiquetSonatres from './pages/Piquets/PiquetSonatres'
import Axios from 'axios'
import PiquetCarre from './pages/Piquets/PiquetCarre';
import Footer from './Component/Footer'
import Langue from './Component/Langue';
import { useTranslation } from 'react-i18next';
import SnackBar from './Component/SnackBar';

function App() {
  return (
    <div className="">
      <Router>
        <Routes>
          <Route path='/home' element={<Home />} />
          <Route path='/' element={<Home />} />
          <Route exact path='/Poutrelle' element={<Poutrelle />} />
          <Route exact path='/rouleaux' element={<Rouleaux />} />
          <Route exact path='/panneaux' element={<Panneaux />} />
          <Route exact path='/cep' element={<CEP />} />
          <Route exact path='/panneau_simple' element={<PanneauSimple />} />
          <Route exact path='/panneau_2d' element={<Panneau2D />} />
          <Route exact path='/panneau_3d' element={<Panneau3D />} />
          <Route exact path='/panneau_haute_securite' element={<PanneauHaute />} />
          <Route exact path='/piquet_rectangulaire' element={<PiquetRec />} />
          <Route exact path='/piquet_60cm_lourd' element={<PiquetLourd />} />
          <Route exact path='/piquet_sonatres' element={<PiquetSonatres />} />
          <Route exact path='/piquet_80cm_carre' element={<PiquetCarre />} />
          
        </Routes>
      </Router>
    </div>
  )
}


const Home=(props)=> {
  const [t, i18n]= useTranslation("global")
  const menuRef= useRef(null)
  const btnRef= useRef(null)
  const [home, setHome]= useState(true)
  const [contact, setContact]= useState(false)
  const [about, setAbout]= useState(false)
  const [email, setEmail]= useState('')
  const [name, setName]= useState('')
  const [phone, setPhone]= useState('')
  const [msg, setMsg]= useState("")
  const [send, setSend]= useState(false)
  const [sendLoad, setSendLoad]= useState(false)
  const [load, setLoad]= useState(true)
  const [loadMap, setLoadMap]= useState(false)
  const [homeSecond, setHomeSecond]= useState(false)
  const [hamOpen, setHamOpen]= useState(false)
  const [value, setValue]= useState()
  const [all, setAll]= useState(true)
  const [pan, setPan]= useState(true)
  const [tr, setTr]= useState(true)
  const [acc, setAcc]= useState(true)
  const [homeActive, setHomeActive]= useState(false)
  const [aboutActive, setAboutActive]= useState(false)
  const [contactActive, setContactActive]= useState(false)
  const mobile= useMediaQuery('(width <= 800px)')
  const navigate= useNavigate()
  const location= useLocation()

  const form = useRef()
  const finish= ()=> {
    setTimeout(() => {
      setMsg('')
      setName('')
      setPhone('')
      setEmail('')
      setSend(true)
      setSendLoad(false)
    }, 5000);
    setTimeout(() => {
      setSend(false)
    }, 3000)
  } 
  const sendEmail = (e) => {
    emailjs.sendForm('service_4lhdc1g', 'template_fxxk38s', form.current, 'KkeYpG9WNE3qdpw7J')
      .then((result) => {
        finish()
      }, (error) => {
          console.log(error.text)
      });
  };
  
  const receiveEmail = (e) => {
    e.preventDefault()
    setSendLoad(true)
    emailjs.sendForm('service_4lhdc1g', 'template_d1p5i6y', form.current, 'KkeYpG9WNE3qdpw7J')
      .then((result) => {
        sendEmail()
      }, (error) => {
          console.log(error.text)
      });
  };
  
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClicks)
      return () => {
        document.removeEventListener("mousedown", handleOutsideClicks)
    }
  }, [hamOpen])

  const handleOutsideClicks =(event)=>{
    const ham= document.querySelector('.ham_menu')
    const hamChild= document.querySelector('.hamburger-react')
    const hamGrand= document.querySelector('.hamburger-react div')
      if(hamOpen && menuRef.current && !menuRef.current.contains(event.target) && !btnRef.current.contains(event.target)){
        setHamOpen(false)
      }
  }

  useEffect(()=> {
    setLoad(true)
    if(location.state) {
      setTimeout(() => {
        setLoad(false)
      }, 500)
    }else {
      setTimeout(() => {
        setLoad(false)
      }, 2000)
    }
  }, [])

  useEffect(()=> {
    if(about){
      setTimeout(() => {
        setLoadMap(false)
      }, 4000);
    }
  }, [about, loadMap])

  useEffect(()=> {
    if (!mobile) {
      setHamOpen(false)
    }
  }, [useMediaQuery('(width <= 800px)')])

  const handleChange = (newValue) => {
    setPhone(newValue)
  }

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
    setShow(false)
    } else {
    setShow(true) 
    }
    setLastScrollY(window.scrollY)
  }

    useEffect(() => {
      window.addEventListener('scroll', controlNavbar);
      return () => {
      window.removeEventListener('scroll', controlNavbar);
      }
    }, [lastScrollY])

    const sectionChange= (state)=> {
      window.scrollTo( {top: "0", behavior: "smooth"} )
      if(state=== "home") {
        setHome(true); setAbout(false); setLoadMap(false); setContact(false)
      }else if(state=== "about") {
        setHome(false); setAbout(true); setLoadMap(true); setContact(false)
      }else {
        setHome(false); setAbout(false); setLoadMap(false); setContact(true)
      }
    }
    useEffect(()=> {
      if(location.state) {
        if(location.state.section=== "home") {
          setHome(true); setAbout(false); setLoadMap(false); setContact(false)
        }else if(location.state.section=== "about") {
          setHome(false); setAbout(true); setLoadMap(true); setContact(false)
        }else {
          setHome(false); setAbout(false); setLoadMap(false); setContact(true)
        }
      }
    }, [])

    const changeLang= (lang)=> {
      i18n.changeLanguage(lang)
      .then(()=> {
        setSnackChange(true)
      })
    }
    const textAr= {
      direction: (t("lang")=== "dz")&& "rtl"
    }
    const [snackChange, setSnackChange]= useState(false)
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return
      }
      setSnackChange(false)
    }
  return (
    <>
      <div className="cover" style= {{transition: "all ease-in-out 1s", zIndex: 2, position: "absolute", top: 0, height: load ? "100vh": 0, width: "100%", background: "#143B38", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <RingLoader
          loading= {load}
          color= {"#ff4136"}
          size= {60}
        />
      </div>
    
    <div className="App">
      <nav style= {{height: !show && 0, opacity: !show && 0, pointerEvents: !show && "none"}}>
        <img src= {logo} alt="" style= {{flex: mobile&& 1, cursor: "pointer"}} onClick= {()=> {window.location.reload()}} />
        {!mobile &&(<><ul style= {textAr}>
          <li id= {home && "isActive"} style= {{color: home && "red"}} onClick= {()=> {setHomeActive(true); setHome(true); setAbout(false); setLoadMap(false); setContact(false)}}>{t("nav.home")}</li>
          <li id= {about && "isActive"} style= {{color: about && "red"}} onClick= {()=> {setAboutActive(true); setHome(false); setAbout(true); setLoadMap(true); setContact(false)}}>{t("nav.about")}</li>
          <li id= {contact && "isActive"} style= {{color: contact && "red"}} onClick= {()=> {setContactActive(true); setHome(false); setAbout(false); setLoadMap(false); setContact(true)}}>{t("nav.contact")}</li>
        </ul></>)}
        <div className='number' style= {{flex: mobile&& 1, height: "100%", direction: (t("lang")=== "dz")&& "rtl"}}>
          <p style= {{color: "red", fontWeight: "bolder", textAlign: "center"}}>{t("nav.number.txt")}: </p>
          <div className="number_list" style= {{width: mobile && "100vw"}}>
            <h5>
              {t("nav.number.sc")}: 
              <center><a href= 'tel:+213770575254'>0770575254</a></center>
              <center><a href= 'tel:+213560451346'>0560451346</a></center>
            </h5>
            <h5>{t("nav.number.sp")}: <center><a href= 'tel:+213770755057'>0770755057</a></center></h5>
            <h5>{t("nav.number.sf")}: <center><a href= 'tel:+213770787052'>0770787052</a></center></h5>
          </div>
        </div>
        <Langue id= "nav" changeLang= {changeLang} />
        {mobile &&<div ref= {btnRef} className="ham_icon" style= {{flex: 1, display: "flex", justifyContent: "flex-end"}}>
          <Hamburger toggled={hamOpen} onToggle={() => setHamOpen(!hamOpen)} />
        </div>}
      
      </nav>
      {!load
      &&<><main style= {textAr}>
        {(hamOpen && mobile) &&<motion.div ref= {menuRef} className="ham_menu" initial={{ display: "none", width: 0 }}
            animate={{  display: "block", width: "50%" }}
            transition={{
              duration: 1,
              delay: 0,
              ease: [0, 0.71, 0.2, 1.01]
            }} >
          <ul>
            <li onClick= {()=> {setHome(true); setAbout(false); setLoadMap(false); setContact(false); setHamOpen(false)}}>{t("nav.home")}</li>
            <li onClick= {()=> {setHome(false); setHomeSecond(true); setAbout(true); setLoadMap(true); setContact(false); setHamOpen(false)}}>{t("nav.about")}</li>
            <li onClick= {()=> {setHome(false); setHomeSecond(true); setAbout(false); setLoadMap(false); setContact(true); setHamOpen(false)}}>{t("nav.contact")}</li>
          </ul>
        </motion.div>}
        {home &&<motion.div className="home">
          <motion.div className= {mobile? "filter_mobile": "filter"} transition= {{ duration: 0.5 }} initial={{y: -500, opacity: 1}} animate={{y: 0, opacity: 1}} exit={{y: 500, opacity: 0}} >
            <h3 style= {{color: (all)&& "#143B38"}} onClick= {()=> {setAll(true); setAcc(true); setTr(true); setPan(true)}}><span>{t("main.home.filter.tous")}</span></h3>
            <h3 style= {{color: (tr && !all)&& "#143B38"}} onClick= {()=> {setAll(false); setAcc(false); setTr(true); setPan(false)}}>
              <span>{t("main.home.filter.treillis.catg")}</span>
              <motion.div className= {mobile ? "filter_sub_mobile" : "filter_sub"} >
                <h5><Link to= "/poutrelle">{t("main.home.filter.treillis.poutrelle")}</Link></h5>
                <h5><Link to= "/rouleaux">{t("main.home.filter.treillis.rouleaux")}</Link></h5>
                <h5><Link to= "/panneaux">{t("main.home.filter.treillis.panneaux")}</Link></h5>
                <h5><Link to= "/cep">{t("main.home.filter.treillis.cep")}</Link></h5>
              </motion.div>
            </h3>
            <h3 style= {{color: (pan && !all)&& "#143B38"}} onClick= {()=> {setAll(false); setAcc(false); setTr(false); setPan(true)}}>
              <span>{t("main.home.filter.panneaux.catg")}</span>
              <div className= {mobile ? "filter_sub_mobile" : "filter_sub"} >
                <h5><Link to= "/panneau_simple">{t("main.home.filter.panneaux.simple")}</Link></h5>
                <h5><Link to= "/panneau_2d">{t("main.home.filter.panneaux.2d")}</Link></h5>
                <h5><Link to= "/panneau_3d">{t("main.home.filter.panneaux.3d")}</Link></h5>
                <h5><Link to= "/panneau_haute_securite">{t("main.home.filter.panneaux.haute")}</Link></h5>
              </div>
            </h3>
            <h3 style= {{color: (acc && !all)&& "#143B38"}} onClick= {()=> {setAll(false); setAcc(true); setTr(false); setPan(false)}}>
              <span>{t("main.home.filter.accessoires.catg")}</span>
              <div className= {mobile ? "filter_sub_mobile" : "filter_sub"}>
                <h5><Link to= "/piquet_rectangulaire">{t("main.home.filter.accessoires.rec")}</Link></h5>
                <h5><Link to= "/piquet_60cm_lourd">{t("main.home.filter.accessoires.lourd")}</Link></h5>
                <h5><Link to= "/piquet_sonatres">{t("main.home.filter.accessoires.sonatres")}</Link></h5>
                <h5><Link to= "/piquet_80cm_carre">{t("main.home.filter.accessoires.carre")}</Link></h5>
              </div>
            </h3>
          </motion.div>
          {/*<motion.div
           className= 'home_text'
            style= {{boxShadow: "0 4px 8px 0 rgba(192, 192, 192, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)", borderRadius: "10px", padding: "20px", background: "#143B38", color: "white"}}
            initial={{ scale: 0 }}
            animate={{ scale: 2 }}
            transition={{
              duration: !homeSecond? 0.8: 0,
              delay: !homeSecond? 2: 0,
              ease: [0, 0.71, 0.2, 1.01]
            }}
            >
            <center><h1>Welcome to our Website !</h1></center>
          </motion.div>*/}
          <AnimatePresence  mode= 'popLayout'>
          <motion.div className="home_prods" layout transition= {{duration: 0.5, ease: [0, 0.71, 0.2, 1.01]}}>
            {tr
            &&
            <><Link style= {{borderRadius: "50px"}} to= '/poutrelle'>
            <motion.div class="flip" layout transition= {{ duration: 0.5 }} initial={{x: 1000, opacity: 1}} animate={{x: 0, opacity: 1}} exit={{x:-1000, opacity: 0}} >
              <div class="flip_inner">
                  <div class="flip_front">
                      <img src= {poutrelle} alt="" />
                  </div>
                  <div class="flip_back">
                    <h3>{t("prods.treillis.poutrelle.title")}</h3>
                    <p>
                      {t("prods.treillis.poutrelle.dscrp")}
                    </p>
                  </div>
              </div>
            </motion.div>
            </Link>
            <Link style= {{borderRadius: "50px"}} to= '/rouleaux'>
            <motion.div class="flip" layout transition= {{ duration: 0.5 }} initial={{x: 1000, opacity: 1}} animate={{x: 0, opacity: 1}} exit={{x:-1000, opacity: 0}} >
              <div class="flip_inner">
                  <div class="flip_front">
                      <img src= {rouleaux} alt="" />
                  </div>
                  <div class="flip_back">
                    <h3>{t("prods.treillis.rouleaux.title")}</h3>
                    <p>
                      {t("prods.treillis.rouleaux.dscrp")}
                    </p>
                  </div>
              </div>
            </motion.div>
            </Link>
            <Link style= {{borderRadius: "50px"}} to= '/panneaux'>
            <motion.div class="flip" layout transition= {{ duration: 0.5}} initial={{x: 1000, opacity: 1}} animate={{x: 0, opacity: 1}} exit={{x:-1000, opacity: 0}}>
              <div class="flip_inner">
                  <div class="flip_front">
                      <img src= {panneau} alt="" />
                  </div>
                  <div class="flip_back">
                    <h3>{t("prods.treillis.panneaux.title")}</h3>
                    <p>
                      {t("prods.treillis.panneaux.dscrp")}
                    </p>
                  </div>
              </div>
            </motion.div>
            </Link>
            <Link style= {{borderRadius: "50px"}} to= "/cep">
            <motion.div class="flip" layout transition= {{ duration: 0.5 }} initial={{x: 1000, opacity: 1}} animate={{x: 0, opacity: 1}} exit={{x:-1000, opacity: 0}}>
              <div class="flip_inner">
                  <div class="flip_front">
                      <img src= {cep} alt="" />
                  </div>
                  <div class="flip_back">
                    <h3>{t("prods.treillis.cep.title")}</h3>
                    <p>
                      {t("prods.treillis.cep.dscrp")}
                    </p>
                  </div>
              </div>
            </motion.div>
            </Link>

            </>}

            {pan
            &&<><Link style= {{borderRadius: "50px"}} to= "/panneau_simple">
            <motion.div class="flip" layout transition= {{ duration: 0.5 }} initial={{y: 1000, opacity: 1}} animate={{y: 0, opacity: 1}} exit={{y:-1000, opacity: 0}}>
              <div class="flip_inner">
                  <div class="flip_front">
                      <img src= {simple} alt="" />
                  </div>
                  <div class="flip_back">
                    <h3>
                      {t("prods.panneaux.simple.title")}
                    </h3>
                    <p>{t("prods.panneaux.simple.dscrp")}</p>
                  </div>
              </div>
            </motion.div>
            </Link>
            <Link style= {{borderRadius: "50px"}} to= "panneau_2d">
            <motion.div class="flip" layout transition= {{ duration: 0.5 }} initial={{y: 1000, opacity: 1}} animate={{y: 0, opacity: 1}} exit={{y:-1000, opacity: 0}}>
              <div class="flip_inner">
                  <div class="flip_front">
                      <img src= {double} alt="" />
                  </div>
                  <div class="flip_back">
                    <h3>
                      {t("prods.panneaux.2d.title")}
                    </h3>
                    <p>{t("prods.panneaux.2d.dscrp")}</p>
                  </div>
              </div>
            </motion.div>
            </Link>
            <Link style= {{borderRadius: "50px"}} to= "/panneau_3d">
            <motion.div class="flip" layout transition= {{ duration: 0.5 }} initial={{y: 1000, opacity: 1}} animate={{y: 0, opacity: 1}} exit={{y:-1000, opacity: 0}}>
              <div class="flip_inner">
                  <div class="flip_front">
                      <img src= {triple} alt="" />
                  </div>
                  <div class="flip_back">
                    <h3>
                      {t("prods.panneaux.3d.title")}
                    </h3>
                    <p>{t("prods.panneaux.3d.dscrp")}</p>
                  </div>
              </div>
            </motion.div>
            </Link>
            <Link style= {{borderRadius: "50px"}} to= "panneau_haute_securite">
            <motion.div class="flip" layout transition= {{ duration: 0.5 }} initial={{y: 1000, opacity: 1}} animate={{y: 0, opacity: 1}} exit={{y:-1000, opacity: 0}}>
              <div class="flip_inner">
                  <div class="flip_front">
                      <img src= {haute} alt="" />
                  </div>
                  <div class="flip_back">
                    <h3>
                      {t("prods.panneaux.haute.title")}
                    </h3>
                    <p>{t("prods.panneaux.haute.dscrp")}</p>
                  </div>
              </div>
            </motion.div>
            </Link>
            </>}

            {acc
            &&<><Link style= {{borderRadius: "50px"}} to= "piquet_rectangulaire">
            <motion.div class="flip" layout transition= {{ duration: 0.5 }} initial={{scale: 0, opacity: 1}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 0}}>
              <div class="flip_inner">
                  <div class="flip_front">
                      <img src= {rec} alt="" />
                  </div>
                  <div class="flip_back">
                    <h3>
                      {t("prods.piquets.rec.title")}
                    </h3>
                    <p>{t("prods.piquets.rec.dscrp")}</p>
                  </div>
              </div>
            </motion.div>
            </Link>
            <Link style= {{borderRadius: "50px"}} to= "piquet_60cm_lourd">
            <motion.div class="flip" layout transition= {{ duration: 0.5 }} initial={{scale: 0, opacity: 1}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 0}}>
              <div class="flip_inner">
                  <div class="flip_front">
                      <img src= {lourdHome} alt="" />
                  </div>
                  <div class="flip_back">
                    <h3>
                      {t("prods.piquets.lourd.title")}
                    </h3>
                    <p>{t("prods.piquets.lourd.dscrp")}</p>
                  </div>
              </div>
            </motion.div>
            </Link>
            <Link style= {{borderRadius: "50px"}} to="piquet_sonatres" >
            <motion.div class="flip" layout transition= {{ duration: 0.5 }} initial={{scale: 0, opacity: 1}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 0}}>
              <div class="flip_inner">
                  <div class="flip_front">
                      <img src= {sonatres} alt="" />
                  </div>
                  <div class="flip_back">
                    <h3>
                      {t("prods.piquets.sonatres.title")}
                    </h3>
                    <p>{t("prods.piquets.sonatres.dscrp")}</p>
                  </div>
              </div>
            </motion.div>
            </Link>
            <Link style= {{borderRadius: "50px"}} to= "piquet_80cm_carre" >
            <motion.div class="flip" layout transition= {{ duration: 0.5 }} initial={{scale: 0, opacity: 1}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 0}}>
              <div class="flip_inner">
                  <div class="flip_front">
                      <img src= {carre} alt="" />
                  </div>
                  <div class="flip_back">
                    <h3>
                      {t("prods.piquets.carre.title")}
                    </h3>
                    <p>{t("prods.piquets.carre.dscrp")}</p>
                  </div>
              </div>
            </motion.div>
            </Link>
            </>}
          </motion.div>
          </AnimatePresence>
        </motion.div>}
        {about &&<div className="about">
          <motion.div className="about_text" transition= {{ duration: 0.5 }} initial={{x: (t("lang")=== "dz")? 1000: -1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: (t("lang")=== "dz")? 1000: -1000, opacity: 1}}>
            <center><div>
              <motion.p transition= {{delay: 0.5, duration: 0.5}} initial={{scale: 0, opacity: 0}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 1}}>
                <motion.span transition= {{ delay: 3.5, duration: 0.25 }} initial={{x: (t("lang")=== "dz")? 1000: -1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}}>{t("main.about.un.animate")}</motion.span>{t("main.about.un.text")}.
              </motion.p>
    
              <motion.p transition= {{delay: 1, duration: 0.5}} initial={{scale: 0, opacity: 0}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 1}}>
                <motion.span transition= {{delay: 4, duration: 0.25}} initial={{x: (t("lang")=== "dz")? 1000: -1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}}>{t("main.about.deux.animate")}</motion.span>{t("main.about.deux.text")}.
              </motion.p>

              <motion.p transition= {{delay: 1.5, duration: 0.5}} initial={{scale: 0, opacity: 0}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 1}}>
                <motion.span transition= {{delay: 4.5, duration: 0.25}} initial={{x: (t("lang")=== "dz")? 1000: -1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}}>{t("main.about.trois.animate")}</motion.span>{t("main.about.trois.text")}.
              </motion.p>

              <motion.p transition= {{delay: 2, duration: 0.5}} initial={{scale: 0, opacity: 0}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 1}}>
                <motion.span transition= {{delay: 5, duration: 0.25}} initial={{x: (t("lang")=== "dz")? 1000: -1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}}>{t("main.about.quatre.animate")}</motion.span>{t("main.about.quatre.text")}.
              </motion.p>

              <motion.p transition= {{delay: 2.5, duration: 0.5}} initial={{scale: 0, opacity: 0}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 1}}>
                <motion.span transition= {{delay: 5.5, duration: 0.25}} initial={{x: (t("lang")=== "dz")? 1000: -1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: 1000, opacity: 1}}>{t("main.about.cinq.animate")}</motion.span>{t("main.about.cinq.text")}.
              </motion.p>
              <motion.p>
                <motion.span transition= {{delay: 6, duration: 1}} initial={{scale: 0, opacity: 0}} animate={{scale: 1, opacity: 1}} exit={{scale: 0, opacity: 1}}>
                  {t("main.about.six.animate")}.
                </motion.span>
              </motion.p>
            </div></center>
          </motion.div>
          <div className="map">
            {(!loadMap && about) ?
              <motion.iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26081.919943068973!2d4.116905574316397!3d35.200490599999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128bb33f64e0f135%3A0xcf0c1d87f28f9cb9!2sSARL%20SONATRES!5e0!3m2!1sfr!2sdz!4v1699859258957!5m2!1sfr!2sdz" width="600" height="450" style= {{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></motion.iframe>
              :
            <motion.div transition= {{ duration: 0.5 }} initial={{x: (t("lang")=== "dz")? -1000: 1000, opacity: 0}} animate={{x: 0, opacity: 1}} exit={{x: (t("lang")=== "dz")? -1000: 1000, opacity: 1}}>
              <ClipLoader
                color="#143B38"
                size={100}
                loading={loadMap}
                cssOverride={{
                  display: "block"
                }}
              />
            </motion.div>
            }
          </div>
        </div>}

        {contact &&<motion.form ref={form} onSubmit= {receiveEmail} className="contact" transition= {{ duration: 0.5 }} initial={{scale: 0, opacity: 1}} animate={{scale: 1, opacity: 1}} exit={{scale: 0}} >
          <div className="contact_text">
            <p>{t("main.contact.p")} !</p>
            <h1>
              {t("main.contact.h")}.
            </h1>
          </div>
          <div className="contact_first">
            <FormControl id= "nn" className='nn' variant="standard" style= {{}}>
              <InputLabel htmlFor="input-with-icon-adornment" style= {{border: "none"}}>
                {t("main.contact.nom")}
              </InputLabel>
              <Input
                name= 'person'
                id="input-with-icon-adornment"
                value= {name}
                onChange= {(e)=> {setName(e.target.value)}}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl id= "nn" variant="standard" style= {{background: "transparent"}}>
              <MuiTelInput name= "phone" value={phone} onChange={handleChange} label= {t("main.contact.tel")} />
            </FormControl>
          </div>
          <FormControl id= "contact_email" variant="standard" style= {{width: "max(90%, 300px)", border: "0.5px solid gray !important"}}>
            <InputLabel htmlFor="input-with-icon-adornment">
              {t("main.contact.email")}
            </InputLabel>
            <Input
              name= 'address'
              value={email}
              onChange= {(e)=> {setEmail(e.target.value)}}
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <AlternateEmailIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          <TextareaAutosize name='message' id= "contact_message" value= {msg} onChange= {(e)=> {setMsg(e.target.value)}} 
            style= {{maxWidth: "90%", width: "90%", maxHeight: "150px", height: "100px", overflowY: "scroll", 
            borderRadius: "10px", border: "#143B38"}} maxRows= {10}
            startAdornment={
              <InputAdornment position="start">
                <AlternateEmailIcon />
              </InputAdornment>
            }
            placeholder= {t("main.contact.msg")}
          />
          <Button type='submit' id= "contact_btn" className= {((name=== '' || phone=== '' || msg=== '' || email=== '') || sendLoad ) && "isDisabled"} variant="contained" >Clickez Pour Envoyer
            {/*<a className= {(name=== '' || phone=== '' || msg=== '' || email=== '') && "isDisabled"} target="_blank" rel="noopener noreferrer" href= {`mailto: sonatres@gnmc-dz.com ?subject= Un message de Ms, Mr ${name} qui a le numéro de téléphone : ${phone} et @ : ${email} &body= ${msg} `} onClick= {()=> {setSend(true); finish()}}>Clickez Pour Envoyer</a>*/}
            {sendLoad &&<SyncLoader 
              color= '#ff4136'
              size= {10}
              style= {{ 
                position: "absolute", top: "50%", left: "50%", translate: "-50% -50%", 
                opacity: "1 !important", cursor: "unset !important"
              }}

            />}
          </Button>
          
        </motion.form>}
        <center>
        <Snackbar open={send} autoHideDuration={5000} onClose= {()=> {setSend(false)}} >
          <Alert onClose= {()=> {setSend(false)}} severity="success" sx={{ width: '100%'}}>
            {t("main.contact.snack")}
          </Alert>
        </Snackbar>
        </center>
      </main>
      <Footer change= {sectionChange} id= "footer_home" home= {true} changeLang= {changeLang}/>
      </>}
      <SnackBar
        change= {snackChange}
        handleClose= {handleClose}
        msg= {`${t("snack")}`}
      />
    </div>
    </>
  );
}

export default App;
