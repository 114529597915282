import {useEffect, Fragment } from 'react'
import '../../Styles/Page.css'
import TreillisInter from '../../Component/TreillisInter'
import { useTranslation } from 'react-i18next'

function Panneaux() {
  useEffect(() => {
    window.scrollTo( {top: "0", behavior: "smooth"} )
  }, [])

  const[t, i18n]= useTranslation("global")

  const avFr= [
    {av: "Les panneaux en acier sont connus pour leur grande résistance structurelle, ce qui les rend capables de supporter des charges importantes. Cela en fait un choix populaire dans la construction de bâtiments et de structures nécessitant une robustesse élevée.", title: "Résistance structurelle:"},
    {av: "Bien que l'acier soit un matériau dense, les panneaux en acier peuvent être conçus pour être relativement légers tout en conservant une résistance élevée. Cela facilite le transport, la manutention et l'installation sur le site de construction.", title: "Légèreté:"},
    {av: "Les panneaux en acier peuvent être préfabriqués en usine, ce qui permet de gagner du temps sur le chantier. La rapidité d'installation contribue à réduire les coûts de main-d'œuvre et à accélérer la réalisation du projet.", title: "Rapidité de construction :"},
    {av: "L'acier est l'un des matériaux les plus recyclables. Les panneaux en acier peuvent être recyclés à la fin de leur vie utile, contribuant ainsi à réduire l'empreinte carbone et les déchets.", title: "Recyclabilité:"},
  ]
  const appFr= [
    {app: "Les treillis soudés en acier sont utilisés dans la construction de bâtiments comme armature pour les planchers, les dalles, les murs et les fondations. Ils contribuent à renforcer la structure globale.", title: "Construction de Bâtiments:"},
    {app: "Les treillis soudés en acier sont souvent utilisés pour créer des cages de ferraillage pour les colonnes et les poteaux dans la construction de structures verticales.", title: "Cages de Ferraillage pour Colonnes et Poteaux:"},
    {app: "Ils sont fréquemment utilisés dans la construction de dalles industrielles en béton pour fournir un soutien structurel supplémentaire.", title: "Dallage Industriel:"},
    {app: "Les treillis soudés en acier sont utilisés dans la construction d'infrastructures routières, tels que les ponts, les murs de soutènement et les éléments structurels des routes.", title: "Infrastructure Routière:"},
    {app: "Ils sont employés dans la construction de tunnels et de viaducs pour renforcer les structures souterraines et surélevées.", title: "Tunnels et Viaducs:"},
  ]

  const avGb= [
    {av: "Steel panels are known for their high structural strength, making them capable of supporting significant loads. This makes them a popular choice in the construction of buildings and structures requiring high strength.", title: "Structural strength:"},
     {av: "Although steel is a dense material, steel panels can be designed to be relatively lightweight while maintaining high strength. This facilitates transportation, handling and installation at the construction site." , title: "Lightness:"},
     {av: "Steel panels can be prefabricated in the factory, saving time on the job site. Speedy installation helps reduce labor costs and speed up project completion." , title: "Speed of construction:"},
     {av: "Steel is one of the most recyclable materials. Steel panels can be recycled at the end of their useful life, helping to reduce carbon footprint and waste.", title: "Recyclability :"},
  ]
  const appGb= [
    {app: "Welded steel mesh is used in building construction as reinforcement for floors, slabs, walls and foundations. It helps strengthen the overall structure.", title: "Building Construction:"} ,
     {app: "Welded steel mesh is often used to create reinforcement cages for columns and posts in the construction of vertical structures.", title: "Reinforcement Cages for Columns and Posts:"},
     {app: "They are frequently used in the construction of industrial concrete slabs to provide additional structural support.", title: "Industrial Slab:"},
     {app: "Welded steel mesh is used in the construction of road infrastructure, such as bridges, retaining walls and structural elements of roads.", title: "Road Infrastructure:"},
     {app: "They are used in the construction of tunnels and viaducts to reinforce underground and elevated structures.", title: "Tunnels and Viaducts:"},
  ]

  const avDz= [
    {av: "تشتهر الألواح الفولاذية بقوتها الهيكلية العالية، مما يجعلها قادرة على تحمل الأحمال الكبيرة. وهذا يجعلها خيارًا شائعًا في تشييد المباني والهياكل التي تتطلب قوة عالية.", title: "القوة الهيكلية:"},
     {av: "على الرغم من أن الفولاذ مادة كثيفة، إلا أنه يمكن تصميم الألواح الفولاذية لتكون خفيفة الوزن نسبيًا مع الحفاظ على قوة عالية. وهذا يسهل النقل والمناولة والتركيب في موقع البناء." , title: "Lightness:"},
     {av: "يمكن تصنيع الألواح الفولاذية مسبقًا في المصنع، مما يوفر الوقت في موقع العمل. يساعد التثبيت السريع على تقليل تكاليف العمالة وتسريع إكمال المشروع." , title: "سرعة البناء:"},
     {av: "الصلب هو أحد أكثر المواد القابلة لإعادة التدوير. يمكن إعادة تدوير الألواح الفولاذية في نهاية عمرها الإنتاجي، مما يساعد على تقليل انبعاثات الكربون والنفايات.", title: "قابلية إعادة التدوير :"},
  ]
  const appDz= [
    {app: "تُستخدم الشبكة الفولاذية الملحومة في تشييد المباني كتعزيز للأرضيات والألواح والجدران والأساسات. فهي تساعد في تقوية الهيكل العام.", title: "تشييد المبنى:"} ,
     {app: "غالبًا ما يتم استخدام الشبكة الفولاذية الملحومة لإنشاء أقفاص تقوية للأعمدة والأعمدة في إنشاء الهياكل الرأسية.", title: "أقفاص التسليح للأعمدة والأعمدة:"},
     {app: "يتم استخدامها بشكل متكرر في بناء الألواح الخرسانية الصناعية لتوفير دعم هيكلي إضافي.", title: "البلاطات الصناعية:"},
     {app: "تُستخدم الشبكة الفولاذية الملحومة في إنشاء البنية التحتية للطرق، مثل الجسور والجدران الاستنادية والعناصر الهيكلية للطرق.", title: "البنية التحتية للطرق:"},
     {app: "تُستخدم في إنشاء الأنفاق والجسور لتدعيم الهياكل تحت الأرض والمرتفعة.", title: "الأنفاق والجسور:"},
  ]
  return (
    <>
      <TreillisInter 
        id= "panneaux"
        GoTo= {"cep"} GoDown= {"rouleaux"}
        pdf= {require('../../pdf/Treillis/panneau.pdf')}
        appFr= {appFr} avFr= {avFr}
        appGb= {appGb} avGb= {avGb}
        appDz= {appDz} avDz= {avDz}
        image= {"panneau.jpg"}
        figureUno= {"panneauUno.jpg"}
        figureDos= {"panneauDos.jpg"}
      />
      </>
      )}
export default Panneaux