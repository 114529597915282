import { Fragment } from "react"
import PanneauxInter from "../../Component/PanneauxInter"

function Panneau3D() {

  const appFr= [
    {app: "Ce sont les barres d'acier principales qui forment la structure du treillis. Elles sont disposées parallèlement les unes aux autres, espacées à une distance spécifique.", title: "Barres Principales (Longitudinales):"},
    {app: "Ces barres d'acier sont soudées perpendiculairement aux barres principales pour former des intersections. Elles créent ainsi une grille régulière.", title: "Barres Transversales (Transversales):"},
    {app: "Les mailles sont les espaces entre les barres principales et transversales. La taille des mailles peut varier en fonction des spécifications du treillis.", title: "Mailles:"},
    {app: "Les points d'intersection entre les barres principales et transversales sont soudés pour assurer la stabilité structurelle du panneau.", title: "Soudure:"},
    {app: "Certains panneaux de treillis simples peuvent avoir des bords renforcés pour améliorer la résistance et prévenir les dommages pendant la manipulation et l'installation.", title: "Bords Renforcés (Facultatif):"},
    {app: "Certains panneaux peuvent être revêtus d'un matériau supplémentaire, comme de l'acier galvanisé, pour améliorer la résistance à la corrosion.", title: "Revêtement (le cas échéant):"},
    {app: "Les dimensions du panneau, telles que la longueur, la largeur et l'épaisseur des barres, déterminent la taille globale et la résistance du treillis.", title: "Dimensions:"},
  ]
  const avFr= [
    {av: "Les panneaux 3D peuvent ajouter une dimension visuelle et esthétique intéressante aux espaces intérieurs et extérieurs, créant des surfaces texturées et dynamiques.", title: "Esthétique:"},
    {av: "Les panneaux 3D peuvent être conçus dans une variété de motifs et de formes personnalisées, offrant ainsi une flexibilité de conception pour répondre aux besoins spécifiques de chaque projet.", title: "Design personnalisé:"},
    {av: "Les panneaux 3D créent un effet de profondeur et de relief, ce qui peut contribuer à donner une impression de dimension et de mouvement dans un espace.", title: "Effet de profondeur:"},
    {av: "Les panneaux 3D peuvent être fabriqués à partir de matériaux durables et résistants, ce qui contribue à leur longévité et à leur performance à long terme.", title: "Matériaux durables:"},
    {av: "Les panneaux 3D permettent aux architectes et aux designers de repousser les limites de l'innovation en créant des conceptions uniques et attractives.", title: "Innovation architecturale:"}
  ]
  const appGb= [
    {app: "These are the main steel bars that form the truss structure. They are arranged parallel to each other, spaced at a specific distance.", title: "Main Bars (Longitudinal):"},
     {app: "These steel bars are welded perpendicular to the main bars to form intersections. They thus create a regular grid.", title: "Transverse Bars (Transversales):"},
     {app: "Meshes are the spaces between the main and crossbars. Mesh sizes may vary depending on truss specifications.", title: "Meshes:"},
     {app: "The intersection points between the main and cross bars are welded to ensure the structural stability of the panel.", title: "Welding:"},
     {app: "Some simple mesh panels may have reinforced edges to improve strength and prevent damage during handling and installation.", title: "Reinforced Edges (Optional):"},
     {app: "Some panels may be coated with an additional material, such as galvanized steel, to improve corrosion resistance.", title: "Coating (if applicable):"},
     {app: "The dimensions of the panel, such as the length, width and thickness of the bars, determine the overall size and strength of the mesh.", title: "Dimensions:"},
  ]
  const avGb= [
    {av: "3D panels can add an interesting visual and aesthetic dimension to interior and exterior spaces, creating textured and dynamic surfaces.", title: "Aesthetic:"},
     {av: "3D panels can be designed in a variety of patterns and custom shapes, providing design flexibility to meet the specific needs of each project.", title: "Custom design:"},
     {av: "3D panels create an effect of depth and relief, which can help give a sense of dimension and movement in a space.", title: "Depth effect:"},
     {av: "3D panels can be made from durable and strong materials, which contributes to their longevity and long-term performance.", title: "Sustainable materials:"},
     {av: "3D panels allow architects and designers to push the boundaries of innovation by creating unique and attractive designs.", title: "Architectural innovation:"}
  ]

  const appDz= [
    {app: "هذه هي القضبان الفولاذية الرئيسية التي تشكل هيكل الجمالون. وهي مرتبة بالتوازي مع بعضها البعض، ومتباعدة على مسافة محددة.", title: "القضبان الرئيسية (الطولية):"},
     {app: "يتم لحام هذه القضبان الفولاذية بشكل عمودي على القضبان الرئيسية لتكوين تقاطعات. وبالتالي يتم إنشاء شبكة منتظمة.", title: "القضبان المستعرضة (المستعرضة):"},
     {app: "الشبكات هي المسافات بين الأعمدة الرئيسية والعارضة. قد تختلف أحجام الشبكات وفقًا لمواصفات الجمالون.", title: "الشبكات:"},
     {app: "يتم لحام نقاط التقاطع بين القضبان الرئيسية والقضبان المتقاطعة لضمان الاستقرار الهيكلي للوحة.", title: "لحام:"},
     {app: "قد تحتوي بعض اللوحات الشبكية البسيطة على حواف معززة لتحسين القوة ومنع الضرر أثناء المعالجة والتركيب.", title: "الحواف المقواة (اختياري):"},
     {app: "قد يتم طلاء بعض الألواح بمادة إضافية، مثل الفولاذ المجلفن، لتحسين مقاومة التآكل.", title: "الطلاء (إن أمكن):"},
     {app: "تحدد أبعاد اللوحة، مثل طول الأشرطة وعرضها وسمكها، الحجم الإجمالي للشبكة وقوتها.", title: "الأبعاد:"}
  ]
  const avDz= [
    {av: "يمكن أن تضيف اللوحات ثلاثية الأبعاد بُعدًا بصريًا وجماليًا مثيرًا للاهتمام إلى المساحات الداخلية والخارجية، مما يؤدي إلى إنشاء أسطح مزخرفة وديناميكية.", title: "Aesthetic:"},
     {av: "يمكن تصميم اللوحات ثلاثية الأبعاد بمجموعة متنوعة من الأنماط والأشكال المخصصة، مما يوفر مرونة في التصميم لتلبية الاحتياجات المحددة لكل مشروع.", title: "تصميم مخصص:"},
     {av: "تنشئ اللوحات ثلاثية الأبعاد تأثيرًا من العمق والتضاريس، مما يمكن أن يساعد في إعطاء إحساس بالبعد والحركة في الفضاء.", title: "تأثير العمق:"},
     {av: "يمكن تصنيع اللوحات ثلاثية الأبعاد من مواد متينة وقوية، مما يساهم في طول عمرها وأدائها على المدى الطويل.", title: "مواد مستدامة:"},
     {av: "تسمح اللوحات ثلاثية الأبعاد للمهندسين المعماريين والمصممين بدفع حدود الابتكار من خلال إنشاء تصميمات فريدة وجذابة.", title: "الابتكار المعماري:"}
  ]
  return (
    <div>
      <PanneauxInter 
        id= "3d"
        GoTo= {"panneau_haute_securite"} GoDown= {"panneau_2d"} image= {'3d.jpg'}
        pdf= {require('../../pdf/Panneaux/3d.pdf')}
        appFr= {appFr} avFr= {avFr}
        appGb= {appGb} avGb= {avGb}
        appDz= {appDz} avDz= {avDz}
        figureUno= {"3dUno.jpg"}
        figureDos= {"3dDos.jpg"}
      />
    </div>
  )
}

export default Panneau3D