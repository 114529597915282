import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

function SnackBar({change, handleClose, msg}) {
  return (
    <Snackbar open={change} transitionDuration={500} autoHideDuration={3000} onClose= {handleClose} style= {{margin: "1rem", bottom: 0, left: 0}} >
        <Alert variant= 'filled' onClose= {handleClose} severity="success" sx={{ width: '100%'}}>
          {msg}
        </Alert>
    </Snackbar>
  )
}

export default SnackBar