import '../../Styles/Page.css'
import '../../index.css'
import { useEffect, Fragment } from 'react';
import TreillisInter from '../../Component/TreillisInter';
import { useTranslation } from 'react-i18next';

function Rouleaux() {
  useEffect(() => {
    window.scrollTo( {top: "0", behavior: "smooth"} )
  }, [])

  const[t, i18n]= useTranslation("global")

  const avFr= [
    {av: "Les rouleaux en acier sont polyvalents et peuvent être utilisés dans une grande variété d'applications, notamment dans la construction, l'industrie automobile, la fabrication de tôles, la production de tubes, etc.", title: "Polyvalence:"},
    {av: "L'acier est connu pour sa résistance mécanique élevée. Les rouleaux en acier peuvent ainsi supporter des charges importantes et sont adaptés à des applications nécessitant une grande robustesse.", title: "Résistance mécanique:"},
    {av: "Les processus de fabrication des rouleaux en acier sont bien établis, ce qui permet une production efficace à grande échelle. Cela se traduit par des coûts de production compétitifs.", title: "Facilité de fabrication:"},
    {av: "Les rouleaux en acier peuvent être stockés de manière compacte, ce qui facilite le stockage dans les entrepôts et les sites de production.", title: "Facilité de stockage:"}
  ]
  const appFr= [
    {app: "Les treillis soudés en acier sont souvent utilisés pour renforcer la maçonnerie, telle que les murs en briques ou en béton. Ils sont incorporés dans le mortier pour améliorer la résistance et la stabilité de la structure.", title: "Renforcement de la Maçonnerie:"},
    {app: "Les treillis soudés en acier sont fréquemment utilisés comme armature dans les dalles en béton. Ils aident à renforcer le béton et à prévenir les fissures dues à la contraction et à l'expansion thermique.", title: "Dallage en Béton:"},
    {app: "Ils peuvent être utilisés dans la construction de chapes en béton pour assurer une répartition uniforme des charges et renforcer la surface.", title: "Chape en Béton:"},
    {app: "Les treillis soudés en acier peuvent être intégrés dans la construction de cloisons et de plafonds pour renforcer la structure globale.", title: "Construction de Cloisons et Plafonds:"},
    {app: "Ils sont utilisés pour construire des clôtures et des barrières, offrant une solution robuste et durable pour délimiter les propriétés ou les zones sécurisées.", title: "Clôtures et Barrières:"},
  ]

  const avGb= [
    {av: "Steel rollers are versatile and can be used in a wide variety of applications, including construction, the automotive industry, sheet metal fabrication, tube production, etc.", title: "Versatility :"},
     {av: "Steel is known for its high mechanical resistance. Steel rollers can therefore withstand significant loads and are suitable for applications requiring great robustness.", title: "Mechanical resistance:"},
     {av: "Steel roller manufacturing processes are well established, enabling efficient production on a large scale. This results in competitive production costs.", title: "Ease of manufacturing:"},
     {av: "Steel rolls can be stored compactly, making it easier to store in warehouses and production sites.", title: "Ease of storage:"}
  ]
  const appGb= [
    {app: "Welded steel mesh is often used to reinforce masonry, such as brick or concrete walls. It is incorporated into mortar to improve the strength and stability of the structure.", title: "Reinforcement of Masonry:"},
     {app: "Welded steel mesh is frequently used as reinforcement in concrete slabs. It helps reinforce concrete and prevent cracking due to contraction and thermal expansion.", title: "Concrete Slabs :"},
     {app: "They can be used in the construction of concrete screeds to ensure uniform load distribution and reinforce the surface.", title: "Concrete Screed:"},
     {app: "Welded steel mesh can be integrated into the construction of partitions and ceilings to strengthen the overall structure.", title: "Construction of Partitions and Ceilings:"},
     {app: "They are used to construct fences and barriers, providing a robust and durable solution for demarcating properties or secure areas.", title: "Fences and Barriers:"},
  ]

  const avDz= [
    {av: "تتميز بكرات الفولاذ بأنها متعددة الاستخدامات ويمكن استخدامها في مجموعة واسعة من التطبيقات، بما في ذلك البناء وصناعة السيارات وتصنيع الصفائح المعدنية وإنتاج الأنابيب وما إلى ذلك.", title: "تعدد الاستخدامات :"},
      {av: "يشتهر الفولاذ بمقاومته الميكانيكية العالية. ولذلك يمكن لبكرات الفولاذ أن تتحمل أحمالًا كبيرة وتكون مناسبة للتطبيقات التي تتطلب متانة كبيرة.", title: "المقاومة الميكانيكية:"},
      {av: "عمليات تصنيع بكرات الفولاذ راسخة، مما يتيح إنتاجًا فعالاً على نطاق واسع. وينتج عن ذلك تكاليف إنتاج تنافسية.", title: "سهولة التصنيع:"},
      {av: "يمكن تخزين بكرات الفولاذ بشكل مضغوط، مما يسهل تخزينها في المستودعات ومواقع الإنتاج.", title: "سهولة التخزين:"}
  ]
  const appDz= [
    {app: "غالبًا ما تُستخدم الشبكة الفولاذية الملحومة لتعزيز أعمال البناء، مثل الطوب أو الجدران الخرسانية. ويتم دمجها في الملاط لتحسين قوة الهيكل وثباته.", title: "تعزيز البناء:"},
      {app: "يتم استخدام الشبكة الفولاذية الملحومة بشكل متكرر كتعزيز في الألواح الخرسانية. فهي تساعد في تقوية الخرسانة ومنع التشقق بسبب الانكماش والتمدد الحراري.", title: "الألواح الخرسانية :"},
      {app: "يمكن استخدامها في إنشاء قدد الخرسانة لضمان التوزيع الموحد للحمل وتعزيز السطح.", title: "ذراع التسوية الخرسانية:"},
      {app: "يمكن دمج شبكة الصلب الملحومة في بناء الأقسام والأسقف لتعزيز الهيكل العام.", title: "إنشاء الأقسام والأسقف:"},
      {app: "يتم استخدامها لبناء الأسوار والحواجز، مما يوفر حلاً قويًا ودائمًا لترسيم العقارات أو المناطق الآمنة.", title: "الأسوار والحواجز:"},
  ]

  return (
    <>
      <TreillisInter 
        id= "rouleaux"
        title= {t("prods.treillis.rouleaux.title")} GoTo= {"panneaux"} GoDown= {"poutrelle"}
        pdf= {require('../../pdf/Treillis/rouleaux.pdf')}
        appFr= {appFr} avFr= {avFr}
        appGb= {appGb} avGb= {avGb}
        appDz= {appDz} avDz= {avDz}
        image= {"rouleaux.jpg"}
        figureUno= {"rouleauxUno.jpg"}
        figureDos= {"rouleauxDos.jpg"}
      />
    </>
  )
}
export default Rouleaux