import { Fragment, useEffect, useState } from "react"
import PageEntete from "../../Component/PageEntete"
import '../../Styles/Panneau.css'
import '../../Styles/Page.css'
import PiquetInter from "../../Component/PiquetInter"
import { useTranslation } from "react-i18next"

function PiquetRec() {

  const appFr= [
    {app: "Les piquets rectangulaires en acier peuvent être utilisés dans l'aménagement paysager pour fixer des bordures, des séparations de zones ou des éléments décoratifs.", title: "Aménagement paysager"},
    {app: "Ils sont souvent employés dans la construction de clôtures, offrant une solution robuste pour délimiter des propriétés ou des zones.", title: "Clôtures"},
    {app: "Dans le secteur agricole, ces piquets peuvent être utilisés pour marquer des zones, fixer des filets de protection, ou soutenir des cultures en croissance.", title: "Agriculture"},
    {app: "Les piquets peuvent être utilisés comme éléments temporaires pour délimiter des zones de construction, soutenir des panneaux d'avertissement ou fixer des bâches de protection.", title: "Construction"},
    {app: "Lors d'événements en plein air tels que des festivals, des foires, ou des événements sportifs, ces piquets peuvent être utilisés pour installer des panneaux de signalisation, délimiter des zones ou soutenir des structures légères.", title: "Événements en plein air"},
    {app: "Ils peuvent être utilisés pour la signalisation temporaire sur les chantiers routiers, fixant des panneaux de signalisation ou des dispositifs de sécurité.", title: "Sécurité routière"}
  ]
  const avFr= [
    {av: "En raison de leur construction en acier, ces piquets sont robustes et durables, ce qui les rend capables de résister à des conditions météorologiques difficiles et à une utilisation intensive.", title: "Robustesse et Durabilité"},
    {av: "La section transversale rectangulaire offre une plus grande surface de contact avec le sol par rapport aux piquets de forme circulaire, améliorant ainsi la stabilité et réduisant le risque de basculement.", title: "Stabilité"},
    {av: "Les piquets rectangulaires en acier sont polyvalents et peuvent être utilisés dans une variété d'applications, de l'aménagement paysager à la construction en passant par l'agriculture et les événements en plein air.", title: "Polyvalence"},
    {av: "Certains modèles de piquets rectangulaires sont conçus avec des bords arrondis ou biseautés, facilitant ainsi leur enfoncement dans le sol sans endommager les autres structures ou équipements.", title: "Facilité d'Installation"},
  ]

  const appGb= [
    {app: "Rectangular steel stakes can be used in landscaping to secure borders, area dividers or decorative elements.", title: "Landscaping"},
    {app: "They are often used in the construction of fences, providing a robust solution for demarcating properties or areas.", title: "Fences"},
    {app: "In the agricultural sector, these stakes can be used to mark areas, secure protective netting, or support growing crops.", title: "Agriculture"},
    {app: "Stakes can be used as temporary elements to mark off construction zones, support warning signs or attach protective tarpaulins.", title: "Construction"},
    {app: "At outdoor events such as festivals, fairs, or sporting events, these stakes can be used to install signage, mark off areas, or support lightweight structures.", title: " Outdoor events"},
    {app: "They can be used for temporary signaling on road construction sites, fixing traffic signs or safety devices.", title: "Road safety"}
  ]
  const avGb= [
    {av: "Due to their steel construction, these stakes are sturdy and durable, making them able to withstand harsh weather conditions and heavy use.", title: "Sturdiness and Durability"},
    {av: "The rectangular cross section provides a greater contact area with the ground compared to circular shaped stakes, improving stability and reducing the risk of tipping.", title: "Stability"},
    {av: "Rectangular steel stakes are versatile and can be used in a variety of applications, from landscaping and construction to agriculture and outdoor events.", title: "Versatility" },
    {av: "Some models of rectangular stakes are designed with rounded or beveled edges, making it easier to drive them into the ground without damaging other structures or equipment.", title: "Ease of Installation"},
  ]
  const appDz= [
    {app: "يمكن استخدام الأوتاد الفولاذية المستطيلة في تنسيق الحدائق لتأمين الحدود أو فواصل المناطق أو العناصر الزخرفية.", title: "المناظر الطبيعية"},
    {app: "يتم استخدامها غالبًا في بناء الأسوار، مما يوفر حلاً قويًا لترسيم الممتلكات أو المناطق.", title: "الأسوار"},
    {app: "في القطاع الزراعي، يمكن استخدام هذه الأوتاد لتحديد المناطق أو تأمين الشباك الواقية أو دعم زراعة المحاصيل.", title: "الزراعة"},
    {app: "يمكن استخدام الأوتاد كعناصر مؤقتة لتحديد مناطق البناء أو دعم علامات التحذير أو ربط القماش المشمع الواقي.", title: "الإنشاء"},
    {app: "في الأحداث الخارجية مثل المهرجانات أو المعارض أو الأحداث الرياضية، يمكن استخدام هذه الأوتاد لتثبيت اللافتات أو وضع علامات على المناطق أو دعم الهياكل خفيفة الوزن.", العنوان: " الأحداث الخارجية"},
    {app: "يمكن استخدامها للإشارات المؤقتة في مواقع إنشاء الطرق أو إصلاح إشارات المرور أو أجهزة السلامة.", "title": "السلامة على الطرق"}
  ]
  const avDz= [
    {av: "نظرًا لبنيتها الفولاذية، فإن هذه الأوتاد قوية ومتينة، مما يجعلها قادرة على تحمل الظروف الجوية القاسية والاستخدام المكثف.", title: "المتانة والمتانة"},
    {av: "يوفر المقطع العرضي المستطيل مساحة تلامس أكبر مع الأرض مقارنة بالأوتاد ذات الشكل الدائري، مما يؤدي إلى تحسين الثبات وتقليل خطر الانقلاب.", title: "الاستقرار"},
    {av: "الأوتاد الفولاذية المستطيلة متعددة الاستخدامات ويمكن استخدامها في مجموعة متنوعة من التطبيقات، بدءًا من تنسيق الحدائق والبناء وحتى الزراعة والأحداث الخارجية.", title: "التنوع" },
    {av: "تم تصميم بعض نماذج الأوتاد المستطيلة بحواف مستديرة أو مشطوفة، مما يسهل دفعها إلى الأرض دون الإضرار بالهياكل أو المعدات الأخرى.", title: "سهولة التثبيت"},
  ]

  return (
    <div>
      <PiquetInter 
        id= "rec"
        GoTo= {"piquet_60cm_lourd"} GoDown= {"piquet_sonatres"}
        pdf= {require('../../pdf/Piquets/rectangulaire.pdf')}
        appFr= {appFr} avFr= {avFr}
        appGb= {appGb} avGb= {avGb}
        appDz= {appDz} avDz= {avDz}
        mainImage= {"rec.jpg"} platine= {"rec_platine.jpg"} fouille= {"rec_fouille.jpg"}
        bouchon= {"rec_bouchon.jpg"} dimension= {"rec_dimension.jpg"}
      />
      {/*
      <PageEntete title= {"PIQUET RECTANGULAIRE"} GoTo= {"piquet_60cm_lourd"} GoDown= {"piquet_sonatres"} />
      <div className="Panneau_main">
        <div className="Panneau_pres" style= {{alignItems: "flex-start"}}>
          <div className="pres" style= {{flexDirection: "row", justifyContent: "space-evenly"}}>
            <h2>PRESENTATION</h2>
            <div className="">
              <h4>Piquet REC 120 x 60 cm</h4>
              <img src= {image} alt="" />
            </div>
            <div className="">
              <h4>Piquet REC Fouille</h4>
              <img src= {fouille} alt="" />
            </div>
            <div className="">
              <h4>Piquet REC Platine</h4>
              <img src= {platine} alt="" />
            </div>
          </div>
          <div className="dimension">
            <h2>DIMENSION</h2>
            <img src= {dimension} alt="" />
            <img src= {bouchon} alt="" />
          </div>
        </div>

        <div style= {{width: "100%", height: "auto", display: "flex", flexDirection: "column", gap: "0", alignItems: "center"}}>
          <h2>DIMENSION PIQUET RECTANGULAIRE 120x60 CM</h2>
          <table className="tb">
            <thead>
              <tr>
                <th className="tbh">FOUILLE</th>
                <th className="tbh">PLATINE</th>
                <th className="tbh"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tbd">Longueur (mm)</td>
                <td className="tbd">Longueur (mm)</td>
                <td className="tbd">Epaisseur (mm)</td>
              </tr>
              <tr>
                <td className="tbd">2500</td>
                <td className="tbd" rowSpan= "2">2100</td>
                <td className="tbd">2.0 à 2.5</td>
              </tr>
              <tr>
                <td className="tbd">3000</td>
                <td className="tbd">2.0 à 2.5</td>
              </tr>
              <tr>
                <td className="tbd">3700</td>
                <td className="tbd" rowSpan= "2">2600</td>
                <td className="tbd">2.0 à 2.5</td>
              </tr>
              <tr>
                <td className="tbd">4800</td>
                <td className="tbd">2.0 à 2.5</td>
              </tr>
            </tbody>
          </table>
        </div>
          <div className="Panneau_second">
            <div className="objet">
                <h3>OBJET</h3>
                <p>Piquet en tube acier galvanisé.</p>
            </div>
            <div className="objet">
                <h3>REVÊTEMENT</h3>
                <p>TRAITEMENT DE SURFACE<br /> &nbsp;Dégraissage, rinçage et phosphatation.</p>
            </div>
            <div className="objet">
                <h3>MATIERES PREMIERES</h3>
                <p>Tube carré en acier galvanisé à chaud<br /> &nbsp;Norme EN 10025-2</p>
            </div>
            <div className="objet">
                <h3>PLASTIFICATION</h3>
                <p>Poudrage polyester (min 80 microns) <br />&nbsp; Polymérisation par cuisson au four.</p>
            </div>
            <div className="objet">
                <h3>ACIER EST DESIGNE PAR DX51 D</h3>
                <p>Norme: EN 10025-2.</p>
            </div>
            <div className="objet">
                <h3>NORME</h3>
                <p>NF EN 10245-4.</p>
            </div>
          </div>
  </div>*/}
    </div>
  )
}

export default PiquetRec