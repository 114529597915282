import { Fragment } from "react"
import PanneauxInter from "../../Component/PanneauxInter"

function PanneauHaute() {

  const appFr= [
    {app: "Les barres d'acier utilisées dans les panneaux de treillis haute sécurité sont généralement plus épaisses et plus robustes, offrant une résistance accrue contre les tentatives de coupe ou de pliage.", title: "Barres Robustes:"},
    {app: "Les mailles du treillis sont souvent conçues avec un espacement réduit pour décourager l'intrusion à travers le treillis. Cela rend également le treillis plus difficile à escalader.", title: "Espacement Réduit des Mailles:"},
    {app: "Les points de soudure aux intersections des barres principales et transversales sont renforcés pour assurer une connexion solide et résister à des forces extrêmes.", title: "Points de Soudure Renforcés:"},
    {app: "Certains panneaux de treillis haute sécurité sont dotés de revêtements spéciaux, tels que la galvanisation, pour résister à la corrosion et maintenir leur intégrité structurelle dans des conditions environnementales difficiles.", title: "Finition Anti-corrosion:"},
    {app: "La conception du treillis peut incorporer des caractéristiques anti-escalade, telles que des pointes ou des bords inclinés, pour dissuader toute tentative d'escalade.", title: "Conception Anti-escalade:"},
    {app: "Les panneaux de treillis haute sécurité sont souvent disponibles en hauteurs plus importantes pour renforcer davantage la sécurité périmétrique.", title: "Hauteur Élevée:"},
    {app: "Les panneaux de treillis haute sécurité sont généralement conformes à des normes spécifiques de sécurité pour garantir leur efficacité dans des scénarios à haut risque.", title: "Normes de Sécurité:"},
    {app: "Certains fabricants proposent des options personnalisées, permettant d'adapter le treillis aux exigences spécifiques du site ou du projet.", title: "Options Personnalisées:"},
  ]
  const avFr= [
    {av: "Les panneaux de haute sécurité sont fabriqués à partir de matériaux et de techniques de construction spécialement conçus pour offrir une résistance accrue contre les tentatives d'intrusion ou de dommages.", title: "Résistance accrue:"},
    {av: "Ces panneaux sont souvent conçus pour résister aux impacts, qu'il s'agisse de chocs physiques intentionnels, d'accidents ou d'autres formes d'impact.", title: "Protection contre les impacts:"},
    {av: "Les panneaux de haute sécurité peuvent résister aux tentatives d'attaques physiques, ce qui les rend adaptés à des applications nécessitant une protection renforcée, comme les installations gouvernementales, les banques, les prisons, etc.", title: "Protection contre les attaques:"},
    {av: "Certains panneaux de haute sécurité sont conçus pour résister aux explosions, offrant une protection supplémentaire dans des environnements sensibles aux risques d'explosion.", title: "Résistance aux explosions:"},
    {av: "Certains panneaux de haute sécurité peuvent être équipés de matériaux résistants au feu pour offrir une protection supplémentaire en cas d'incendie.", title: "Protection contre le feu:"},
    {av: "Les panneaux de haute sécurité sont souvent fabriqués conformément à des normes strictes de sécurité et de performance, assurant ainsi une protection fiable.", title: "Conformité aux normes de sécurité:"},
  ]

  const appGb= [
    {app: "Steel bars used in high security mesh panels are typically thicker and sturdier, providing increased resistance against cutting or bending attempts.", title: "Heavy Duty Bars:"},
     {app: "The meshes of the trellis are often designed with reduced spacing to discourage intrusion through the mesh. This also makes the trellis more difficult to climb.", title: "Reduced Mesh Spacing:"},
     {app: "The weld points at the intersections of the main and cross bars are reinforced to ensure a strong connection and withstand extreme forces.", title: "Reinforced Weld Points:"},
     {app: "Some high security mesh panels have special coatings, such as galvanizing, to resist corrosion and maintain their structural integrity in harsh environmental conditions.", title: "Anti-corrosion finish:"},
     {app: "The trellis design may incorporate anti-climbing features, such as spikes or angled edges, to deter climbing attempts.", title: "Anti-climbing Design:"},
     {app: "High security mesh panels are often available in greater heights to further enhance perimeter security.", title: "High Height:"},
     {app: "High security mesh panels generally comply with specific safety standards to ensure their effectiveness in high-risk scenarios.", title: "Safety Standards:"},
     {app: "Some manufacturers offer custom options, allowing the mesh to be tailored to specific site or project requirements.", title: "Custom Options:"},
  ]
  const avGb= [
    {av: "High security signs are manufactured using materials and construction techniques specifically designed to provide increased resistance against attempted intrusion or damage.", title: "Increased resistance:"},
     {av: "These panels are often designed to withstand impacts, whether from intentional physical shocks, accidents or other forms of impact.", title: "Impact protection:"},
     {av: "High security panels can resist attempted physical attacks, making them suitable for applications requiring enhanced protection, such as government facilities, banks, prisons, etc.", title: "Protection against attacks:"},
     {av: "Some high security panels are designed to resist explosions, providing additional protection in environments susceptible to explosion hazards.", title: "Explosion resistance:"},
     {av: "Some high security signs may be fitted with fire resistant materials to provide additional protection in the event of a fire.", title: "Fire protection:"},
     {av: "High security signs are often manufactured to strict safety and performance standards, ensuring reliable protection.", title: "Compliance with safety standards:"}
  ]

  const appDz= [
    {app: "عادةً ما تكون القضبان الفولاذية المستخدمة في الألواح الشبكية عالية الأمان أكثر سمكًا وثباتًا، مما يوفر مقاومة متزايدة ضد محاولات القطع أو الثني.", title: "القضبان شديدة التحمل:"},
     {app: "غالبًا ما يتم تصميم شبكات الشبكة مع تباعد أقل لمنع التطفل عبر الشبكة. وهذا أيضًا يجعل تسلق الشبكة أكثر صعوبة.", title: "Reduced Mesh Spacing:"},
     {app: "تم تعزيز نقاط اللحام عند تقاطعات القضبان الرئيسية والقضبان المتقاطعة لضمان اتصال قوي وتحمل القوى الشديدة.", title: "نقاط اللحام المعززة:"},
     {app: "تحتوي بعض الألواح الشبكية عالية الأمان على طلاءات خاصة، مثل الجلفنة، لمقاومة التآكل والحفاظ على سلامتها الهيكلية في الظروف البيئية القاسية.", title: "لمسة نهائية مضادة للتآكل:"},
     {app: "قد يشتمل تصميم التعريشة على ميزات مقاومة التسلق، مثل المسامير أو الحواف المائلة، لردع محاولات التسلق.", title: "تصميم مضاد للتسلق:"},
     {app: "غالبًا ما تتوفر اللوحات الشبكية عالية الأمان على ارتفاعات أكبر لتعزيز أمان المحيط بشكل أكبر.", title: "High Height:"},
     {app: "تتوافق اللوحات الشبكية عالية الأمان بشكل عام مع معايير أمان محددة لضمان فعاليتها في السيناريوهات عالية المخاطر.", title: "معايير السلامة:"},
     {app: "تقدم بعض الشركات المصنعة خيارات مخصصة، مما يسمح بتخصيص الشبكة وفقًا لمتطلبات موقع أو مشروع محدد.", title: "Custom Options:"}
  ]
  const avDz= [
    {av: "يتم تصنيع لافتات الأمان العالية باستخدام مواد وتقنيات بناء مصممة خصيصًا لتوفير مقاومة متزايدة ضد محاولات الاقتحام أو الضرر.", title: "زيادة المقاومة:"},
     {av: "غالبًا ما يتم تصميم هذه اللوحات لتحمل التأثيرات، سواء الناتجة عن الصدمات الجسدية المتعمدة أو الحوادث أو أشكال التأثير الأخرى.", title: "الحماية من الصدمات:"},
     {av: "يمكن للوحات الأمنية المشددة مقاومة محاولات الهجمات الجسدية، مما يجعلها مناسبة للتطبيقات التي تتطلب حماية معززة، مثل المرافق الحكومية والبنوك والسجون وما إلى ذلك.", title: "الحماية ضد الهجمات:"},
     {av: "تم تصميم بعض اللوحات ذات الأمان العالي لمقاومة الانفجارات، وتوفير حماية إضافية في البيئات المعرضة لمخاطر الانفجار.", title: "مقاومة الانفجار:"},
     {av: "قد يتم تجهيز بعض لافتات الأمان العالية بمواد مقاومة للحريق لتوفير حماية إضافية في حالة نشوب حريق.", title: "الحماية من الحرائق:"},
     {av: "يتم تصنيع علامات الأمان العالية غالبًا وفقًا لمعايير السلامة والأداء الصارمة، مما يضمن حماية موثوقة.", title: "الامتثال لمعايير السلامة:"}
  ]
  return (
    <div>
      <PanneauxInter
        id= "haute" 
        GoTo= {"panneau_simple"} GoDown= {"panneau_3d"} image= {'haute.jpg'}
        pdf= {require('../../pdf/Panneaux/haute_securite.pdf')}
        appFr= {appFr} avFr= {avFr}
        appGb= {appGb} avGb= {avGb}
        appDz= {appDz} avDz= {avDz}
        figureUno= {"hauteUno.jpg"}
        figureDos= {"hauteDos.jpg"}
      />
    </div>
  )
}

export default PanneauHaute