import { Fragment, useEffect } from "react"
import PageEntete from "../../Component/PageEntete"
import '../../Styles/Panneau.css'
import '../../Styles/Page.css'
import PiquetInter from "../../Component/PiquetInter"


function PiquetSonatres() {

  const appFr= [
    {app:  "Dégraissage, rinçage et phosphatation", title: "Traitement de surface:"},
    {app: "Poudrage polyester (min 80 microns) Polymérisation par cuisson au four Norme: NF EN 10245-4", title: "Plastification:"},
    {app: "Dans le domaine agricole, les piquets Sonatres peuvent être utilisés pour soutenir des filets de protection autour des cultures, protégeant ainsi les plantes des animaux ou des conditions météorologiques défavorables.", title: "Soutien de Filets Agricoles:"},
    {app: "Ils peuvent être utilisés pour la signalisation temporaire sur les chantiers routiers, fixant des panneaux de signalisation ou des dispositifs de sécurité.", title: "Sécurité routière"},
    {app: "Les piquets Sonatres fournissent un support vertical qui aide à maintenir la structure des rangées de vignes, en particulier dans les vignobles où la culture est en rangées.", title: "Soutien structurel:"},
  ]
  const avFr= [
    {av: "La section transversale rectangulaire offre une plus grande surface de contact avec le sol par rapport aux piquets de forme circulaire, améliorant ainsi la stabilité et réduisant le risque de basculement.", title: "Stabilité"},
    {av: "Les piquets Sonatres en acier sont polyvalents et peuvent être utilisés dans une variété d'applications, de l'aménagement paysager à la construction en passant par l'agriculture et les événements en plein air.", title: "Polyvalence"},
    {av: "Les piquets Sonatres en acier sont adaptés à une utilisation intensive, ce qui les rend appropriés pour des applications permanentes ou temporaires dans des conditions exigeantes.", title: "Utilisation Intensive:"},
    {av: "Les piquets Sonatres sont bien construits et durables peuvent offrir un support à long terme, réduisant ainsi le besoin de remplacement fréquent.", title: "Durabilité:"},
    {av: "Ils permettent une utilisation efficace de l'espace dans le vignoble, en maintenant une disposition régulière des vignes.", title: "Optimisation de l'espace:"},
  ]

  const appGb= [
    {app: "Degreasing, rinsing and phosphating", title: "Surface treatment:"},
     {app: "Polyester powder coating (min 80 microns) Polymerization by baking in the oven Standard: NF EN 10245-4", title: "Plasticization:"},
     {app: "In the agricultural sector, Sonatres stakes can be used to support protective nets around crops, thus protecting plants from animals or adverse weather conditions.", title: "Support of Agricultural Nets:"},
     {app: "They can be used for temporary signaling on road construction sites, fixing traffic signs or safety devices.", title: "Road safety"},
     {app: "Sonatres stakes provide vertical support that helps maintain the structure of rows of vines, especially in vineyards where the crop is in rows.", title: "Structural support:"},
  ]
  const avGb= [
    {av: "The rectangular cross section provides a greater contact area with the ground compared to circular shaped stakes, improving stability and reducing the risk of tipping.", title: "Stability"},
    {av: "Sonatres steel stakes are versatile and can be used in a variety of applications, from landscaping and construction to agriculture and outdoor events.", title: "Versatility" },
    {av: "Sonatres steel stakes are suitable for intensive use, making them suitable for permanent or temporary applications in demanding conditions.", title: "Intensive Use:"},
    {av: "Sonatres stakes are well constructed and durable and can provide long-term support, reducing the need for frequent replacement.", title: "Durability:"},
    {av: "They allow efficient use of space in the vineyard, maintaining a regular arrangement of the vines.", title: "Optimization of space:"},
  ]

  const appDz= [
    {app: "إزالة الشحوم والشطف والفوسفات", title: "المعالجة السطحية:"},
     {app: "طلاء مسحوق البوليستر (80 ميكرون على الأقل) البلمرة عن طريق الخبز في الفرن القياسي: NF EN 10245-4", title: "التلدين:"},
     {app: "في القطاع الزراعي، يمكن استخدام أوتاد سوناتريس لدعم شبكات الحماية حول المحاصيل، وبالتالي حماية النباتات من الحيوانات أو الظروف الجوية السيئة.", title: "دعم الشبكات الزراعية:"},
     {app: "يمكن استخدامها للإشارات المؤقتة في مواقع إنشاء الطرق أو إصلاح إشارات المرور أو أجهزة السلامة.", title: "السلامة على الطرق"},
     {app: "توفر حصص سوناتريس دعمًا رأسيًا يساعد في الحفاظ على بنية صفوف الكروم، خاصة في مزارع الكروم حيث يكون المحصول في صفوف.", title: "الدعم الهيكلي:"},
  ]
  const avDz= [
    {av: "يوفر المقطع العرضي المستطيل مساحة تلامس أكبر مع الأرض مقارنة بالأوتاد ذات الشكل الدائري، مما يؤدي إلى تحسين الثبات وتقليل خطر الانقلاب.", title: "الاستقرار"},
     {av: "تتميز أوتاد Sonatres الفولاذية بأنها متعددة الاستخدامات ويمكن استخدامها في مجموعة متنوعة من التطبيقات، بدءًا من تنسيق الحدائق والبناء وحتى الزراعة والفعاليات الخارجية.", title: "التنوع" },
     {av: "أوتاد سوناتريس الفولاذية مناسبة للاستخدام المكثف، مما يجعلها مناسبة للتطبيقات الدائمة أو المؤقتة في الظروف الصعبة.", title: "الاستخدام المكثف:"},
     {av: "أوتاد Sonatres مبنية بشكل جيد ومتينة ويمكن أن توفر دعمًا طويل الأمد، مما يقلل الحاجة إلى الاستبدال المتكرر.", title: "المتانة:"},
     {av: "إنها تسمح بالاستخدام الفعال للمساحة في الكرم، مع الحفاظ على ترتيب منتظم للكروم.", title: "تحسين المساحة:"}
  ]

  return (
    <div>
      <PiquetInter 
        id= "sonatres"
        GoTo= {"piquet_80cm_carre"} GoDown= {"piquet_60cm_lourd"}
        pdf= {require('../../pdf/Piquets/sonatres.pdf')}
        appFr= {appFr} avFr= {avFr}
        appGb= {appGb} avGb= {avGb}
        appDz= {appDz} avDz= {avDz}
        mainImage= {"sonatres.jpg"} platine= {"sonatres_platine.jpg"} fouille= {"sonatres_fouille.jpg"}
        bouchon= {"sonatres_bouchon.jpg"} dimension= {"sonatres_dimension.jpg"}
      />


      {/*
      <PageEntete title= {"PIQUET SONATRES"} GoTo= {"piquet_rectangulaire"} GoDown= {"piquet_60cm_lourd"} />
      <div className="Panneau_main">
        <div className="Panneau_pres" style= {{alignItems: "flex-start"}}>
          <div className="pres"  style= {{flexDirection: "row", justifyContent: "space-evenly"}}>
            <h2>PRESENTATION</h2>
            <div className="">
              <h4>Piquet Sonatres</h4>
              <img src= {sonatres} alt="" />
            </div>
            <div className="">
              <h4>Piquet Sonatres (Fouille)</h4>
              <img src= {fouille} alt="" />
            </div>
            <div className="">
              <h4>Piquet Sonatres (Platine)</h4>
              <img src= {platine} alt="" />
            </div>
          </div>
          <div className="dimension">
            <h2>DIMENSION</h2>
            <img src= {dimension} alt="" />
            <img src= {bouchon} alt="" />
          </div>
        </div>

        <div style= {{width: "100%", height: "auto", display: "flex", flexDirection: "column", gap: "0", alignItems: "center"}}>
          <h2>DIMENSION PIQUET PROFILE SONATRES</h2>
          <table className="tb">
            <thead>
              <tr>
                <th className="tbh">FOUILLE</th>
                <th className="tbh">PLATINE</th>
                <th className="tbh"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tbd">1000</td>
                <td className="tbd">700</td>
                <td className="tbd">1</td>
              </tr>
              <tr>
                <td className="tbd">1500</td>
                <td className="tbd">1100</td>
                <td className="tbd">1</td>
              </tr>
              <tr>
                <td className="tbd">2000</td>
                <td className="tbd">1600</td>
                <td className="tbd">1</td>
              </tr>
              <tr>
                <td className="tbd">2500</td>
                <td className="tbd">2100</td>
                <td className="tbd">1</td>
              </tr>
              <tr>
                <td className="tbd">2700</td>
                <td className="tbd">2300</td>
                <td className="tbd">1</td>
              </tr>
              <tr>
                <td className="tbd">3000</td>
                <td className="tbd">2500</td>
                <td className="tbd">1</td>
              </tr>
            </tbody>
          </table>
        </div>
          <div className="Panneau_second">
            <div className="objet">
                <h3>OBJET</h3>
                <p>Piquet en tôle acier profilé à froid.</p>
            </div>
            <div className="objet">
                <h3>REVÊTEMENT</h3>
                <p>TRAITEMENT DE SURFACE<br /> &nbsp;Dégraissage, rinçage et phosphatation.</p>
            </div>
            <div className="objet">
                <h3>MATIERES PREMIERES</h3>
                <p>Tôle acier galvanisé à chaud.</p>
            </div>
            <div className="objet">
                <h3>NORME</h3>
                <p>NF EN 10245-4.</p>
            </div>
          </div>
      </div>
  */}
    </div>
  )
}

export default PiquetSonatres