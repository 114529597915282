import * as React from 'react'
import { useEffect, useState } from 'react'
import '../Styles/Page.css'
import "/node_modules/flag-icons/css/flag-icons.min.css"
import { useTranslation } from 'react-i18next'


function Langue({id, changeLang, show}) {
  const [t, i18n]= useTranslation("global")
  const [change, setChange]= useState(false)
  const [ready, setReady]= useState(true)
  const [langue, setLangue]= useState("")
  /*const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  })*/
/*
  useEffect(()=> {
    if(langue!== "" && ready) {
      setChange(true)
      setReady(false)
    }
    setTimeout(() => {
      setReady(true)
    }, 3000)
  }, [t("lang")])
*/
  const footer= {
    width: "100px"
  }
  const nav= {
    flex: 1
  }
  return (
    <>
      <div className= 'Langue' style= {(id=== "nav")? nav: footer} >
          <p><span class= {`fi fi-${t("lang")}`}></span></p>
          <ul>
              <li><span class="fi fi-fr" style= {{pointerEvents: !ready&& "none"}} onClick= {()=> {changeLang("fr"); setLangue("Français") }} transition= { {duration: 0.25} } animate= {{scale: 1, opacity: 1}} exit= {{scale: 0, opacity: 0}}></span></li>
              <li><span class="fi fi-gb" style= {{pointerEvents: !ready&& "none"}} onClick= {()=> {changeLang("en"); setLangue("English") }} transition= { {duration: 0.25} } animate= {{scale: 1, opacity: 1}} exit= {{scale: 0, opacity: 0}}></span></li>
              <li><span class="fi fi-dz" style= {{pointerEvents: !ready&& "none"}} onClick= {()=> {changeLang("ar"); setLangue("العربية") }} transition= { {duration: 0.25} } animate= {{scale: 1, opacity: 1}} exit= {{scale: 0, opacity: 0}}></span></li>
          </ul>
      </div>
    </>
  )
}

export default Langue